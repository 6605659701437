import React, { FC } from 'react'
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import NoDataCom from '@/components/NoDataCom';
import RegisterSuccessIcon from "@/assets/image/login/register-success.png";
import styles from './RegisterSuccess.module.scss'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendWelcomeText } from '@/api/register';

interface IRegisterSuccessProps {
}

const RegisterSuccess: FC<IRegisterSuccessProps> = (props) => {
	const history = useHistory();
	const { t, i18n } = useTranslation();

	return (
		<div className={styles.root}>
			<HeadComponent />
			<div className={styles.rootContent}>
				<NoDataCom
					title={t("等待认证")}
					subtitle={t('感谢您在Wapipay注册。一经核实，我们将通知你。')}
					imgSrc={RegisterSuccessIcon}
					btnTitle={t("完成")}
					btnClick={() => {
						sendWelcomeText()
						history.push('/home')
					}}
				/>
			</div>
			<FooterComponent />
		</div>
	)
}

export default RegisterSuccess
