import React, { FC, ReactElement, useState, useEffect, useMemo } from 'react'
import { Form, Button, message, Upload, Progress } from 'antd'
import DocumentsIcon1 from '@/assets/image/login/documents-icon1.png';
import DocumentsIcon2 from '@/assets/image/login/documents-icon2.png';
import LimitedIcon from '@/assets/image/login/limited-icon.svg';
import TransferIcon from '@/assets/image/login/transfer-icon.svg';
import PatnershipIcon from '@/assets/image/login/patnership-icon.svg';
import DeleteIcon from '@/assets/image/login/delete-icon.png';
import styles from './DocumentsCom.module.scss'
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

interface IDocumentsComProps {
  setFormData: (data: any) => void;
  formData: any;
  fileList: any[];
  setFileList: (data: any[]) => void;
  handleSubmit: (setLoading?: (flag: boolean) => void, attachmentInformation?: string) => void;
}

const DocumentsCom: FC<IDocumentsComProps> = (props) => {
  const { setFormData, formData, fileList, setFileList, handleSubmit } = props;
  const [form] = Form.useForm();
  // const [fileList, setFileList] = useState<any[]>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation();
  const fileTitleList = [
    { label: t("商业登记证或营业执照复印件") },
    { label: t("身份证明复印件-身份证/护照") },
    { label: t("KRA徽章复印件-个人或公司") },
    { label: t("水电费单或商业照片复印件，以确认该业务存在") },
    { label: t("照片/自拍") },
  ]

  const fileLimitedCompanyList = [
    { label: t("一份公司注册证书复印件") },
    { label: t("不超过3个月的CR12复印件"), subLabel: `(${t("展示董事及股东的文件")})` },
    { label: t("一份公司组织大纲和章程复印件") },
    { label: t("股东、董事/实益拥有人身份证明文件"), subLabel: `(${t("25%及以上直接及/或间接持股股东")})` },
    { label: t("公司识别码证书复印件") },
    { label: t("营业地址证明"), subLabel: `(${t("水电费单、租赁协议、营业许可证")})` },
    { label: t("公司董事会关于授权与Wapi Pay合作的决议") },
  ]

  const MoneyTransferOperatorsList = [
    { label: t("金融监管机构/其他监管机构（如适用）签发的《贸易许可证》复印件") },
    { label: t("公司注册证书/公司注册证复印件") },
    { label: t("一份公司组织大纲和章程复印件") },
    { label: t("不超过3个月的CR12复印件"), subLabel: `(${t("展示董事及股东的文件")})` },
    { label: t("董事及股东身份证明文件"), subLabel: `(${t("居民身份证/护照")})` },
    { label: t("一份公司合规税务证明复印件") },
    { label: t("董事及股东税号证明书") },
    { label: t("受益所有人名单及其身份证明文件"), subLabel: `(${t("25%及以上直接及/或间接持股股东")})` },
    { label: t("公司董事会关于授权与Wapi Pay合作的决议") },
    { label: t("公司办公室的营业地址证明"), subLabel: `(${t("近3个月")})` },
    { label: t("公司简介"), subLabel: `(${t("清晰地说明商业的本质")})` },
    { label: t("已填妥的mto KYC问卷（见附件）") },
    { label: t("最新的反洗钱/KYC政策文件") },
    { label: t("公司风险管理政策") },
  ]

  const onFinish = async () => {
    // setFormData(formData ? Object.assign(formData, values) : values);
    const attachmentInformation: any[] = [];
    if (fileList && fileList.length) {
      fileList.forEach((item: any) => {
        const { response } = item || {};
        const { data = [] } = response || {}
        if (data && data.length) {
          attachmentInformation.push(data[0]?.filePath);
        }
      })
    } else {
      message.warning(t("请至少上传一个文件。"));
      return;
    }
    handleSubmit(setSubmitLoading, attachmentInformation.length ? attachmentInformation.join(',') : '');
  }

  const onFileChange = (e: any) => {
    const { status } = e.file;
    setFileList(e.fileList);
    if (status === 'done') {

    }
  }

  const deleteFile = (i: number) => {
    const newList = _.cloneDeep(fileList);
    newList?.splice(i, 1);
    setFileList(newList);
  }

  const onRemove = (file: any, fileList: any) => {
    const i = fileList.findIndex((item: any) => item.uid === file.uid);
    const newList = _.cloneDeep(fileList);
    newList?.splice(i, 1);
    setFileList(newList);
  }

  const businessTypeData = useMemo(() => {
    switch (formData.businessType) {
      case 1:
        return {
          title: t("独资/合伙"),
          options: fileTitleList
        }
      case 2:
        return {
          title: t("有限公司"),
          options: fileLimitedCompanyList
        }
      case 3:
        return {
          title: t("汇款运营商"),
          options: MoneyTransferOperatorsList
        }
      default:

        return {
          title: t(""),
          options: []
        }
    }
  }, [formData.businessType])

  return (
    <div className={styles.root}>
      <div className={styles.uploadContainer}>
        <div className={styles.registerTitle}>{t("请提供额外的文件")}</div>
        <div className={styles.subtitle}>{t("我们需要这些文件来认证您的账户")}</div>
        <div className={styles.uploadWarp}>
          <div className={styles.uploadWarpL}>
            <div className={styles.WarpLeftTitle}>
              <img alt='' src={DocumentsIcon1} className={styles.icon} />
              {t("需要的文件")} ({businessTypeData.title})
            </div>
            <div className={styles.fileTitleList}>
              {
                businessTypeData.options.map((item: any, index: number) => (
                  <div className={styles.fileTitleLi} key={`file-title-${index}`}>
                    <div className={styles.circular} />
                    <div className={styles.labelWarp}>
                      <div className={styles.label}>{item.label}</div>
                      {item.subLabel && (<div className={styles.subLabel}>{item.subLabel}</div>)}
                    </div>
                  </div>
                ))
              }
            </div>
            <div className={styles.WarpLeftTips}>{t("请上传所有需要的文件。")}</div>
          </div>
          <div className={styles.uploadWarpR}>
            <UploadComponent
              accept='.jpg,.png,.jpeg,.bmp,.gif,.pdf'
              onChange={(e) => onFileChange(e)}
              multiple={true}
              maxCount={10}
              setFileList={setFileList}
              itemRender={(originNode: ReactElement, file: any, fileList: any) => (
                <div key={`file-li-${file.uid}`} className={`${styles.fileLi} ${file.status === 'error' && styles.errorFile}`}>
                  <div className={styles.fileLiL}>
                    <div className={styles.fileName}>{file?.name}</div>
                    {/* { item.percent < 100 && (<Progress className={styles.progress} showInfo={false} percent={item.percent} />) } */}
                  </div>
                  <img alt='' src={DeleteIcon} className={styles.deleteIcon} onClick={() => onRemove(file, fileList)} />
                </div>
              )}
              fileList={fileList}
              disabled={fileList && fileList.length >= 10}
            >
              <div className={styles.uploadBox}>
                <img alt='' src={DocumentsIcon2} className={styles.uploadIcon} />
                <p className="ant-upload-text">{t("请点击或者拖拽文件到此处以上传")}</p>
                <p className="ant-upload-hint">{t("支持JPG、JPGE、PNG、PDF")} </p>
              </div>
            </UploadComponent>
            {/* <div className={styles.fileList}>
              {
                fileList && fileList.map((item: any, index: number) => (
                  <div key={`file-li-${index}`} className={`${styles.fileLi} ${item.status === 'error' && styles.errorFile}`}>
                    <div className={styles.fileLiL}>
                      <div className={styles.fileName}>{item?.name}</div>
                    </div>
                    <img alt='' src={DeleteIcon} className={styles.deleteIcon} onClick={() => deleteFile(index)} />
                  </div>
                ))
              }
            </div> */}
          </div>
        </div>
        <Button className='submit-button' loading={submitLoading} onClick={onFinish}>{t("提交")}</Button>
      </div>
    </div>
  )
}

export default DocumentsCom
