import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { store } from '../index'

export const getParameters = (url?: string) => {
  const href = url ?? window.location.href
  const query = href.substring(href.indexOf('?') + 1);
  const vars = query.split("&");
  const obj: any = {}
  for (var i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    obj[pair[0]] = pair[1]
  }
  return obj;
}

/**
 *  文件下载
 * @param url 下载地址
 * @param fileName 文件名称
 */
export function downloadFile(url: string, fileName: string) {
  let xhr: XMLHttpRequest = new XMLHttpRequest();
  // if (window.XMLHttpRequest) {
  //   xhr = new XMLHttpRequest()
  // } else {
  //   // eslint-disable-next-line no-undef
  //   // xhr = new ActiveXObject('Microsoft.XMLHTTP')
  // }
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = function () {
    if (this.status === 200) {
      // downloadFile(fileName, xhr.response)
      const fileBlob = new Blob([xhr.response]);
      const oa = document.createElement("a");
      oa.href = URL.createObjectURL(fileBlob);
      oa.download = fileName;
      document.body.appendChild(oa);
      oa.click();
    }
  }
  xhr.send()
}

// 文件查看，打开新窗口
export const showFile = (url?: string) => {
  if (!url) return;
  const oa = document.createElement("a");
  oa.href = url;
  oa.target = '_blank';
  // oa.download = fileName;
  document.body.appendChild(oa);
  oa.click();
}

export const checkValidNumber = (checkNumber: string) => {
  let str = checkNumber;
  const len1 = str.substring(0, 1);
  const len2 = str.substring(1, 2);
  if (len1 === '0' && len2 !== '.') {
    str = len1;
  }
  if (str.includes('.')) {
    const str_ = str.substring(str.indexOf('.') + 1);
    if (str_.includes('.')) {
      str = str.substring(0, str.indexOf('.') + str_.indexOf('.') + 1);
    }
  }
  str = str.replace(/[^\d^\.]+/g, '')
  // str = str.replace(/\.\d\d$/, '')
  return str;
}

// 校验表单是否全部填写
export const checkDisabled = (form: any, keyLen: number, optionsKey: string[] = []) => {
  const obj = form?.getFieldsValue();
  const arr = [];
  for (const key in obj) {
    if (obj[key] && !optionsKey.includes(key)) arr.push(obj[key]);
  }
  return keyLen === arr.length;
}

// interface IConfirmProps {}

// export const showConfirm = () => {
//   Modal.confirm({
//     title: 'Are you sure delete this task?',
//     content: 'Some descriptions',
//     okText: 'Yes',
//     okType: 'danger',
//     cancelText: 'No',
//     onOk() {
//       console.log('OK');
//     },
//     onCancel() {
//       console.log('Cancel');
//     },
//   });
// }

// export const download = (title: string, ref: any): Promise<void> => {
//   return new Promise((resolve, reject) => {
//     try {
//       html2canvas(ref.current, {
//         allowTaint: false
//       }).then(canvas => {
//         let contentWidth = canvas.width
//         let contentHeight = canvas.height
//         let pageHeight = contentWidth / 592.28 * 841.89
//         let leftHeight = contentHeight
//         let position = 0
//         let imgWidth = 595.28
//         let imgHeight = 592.28 / contentWidth * contentHeight
//         let pageData = canvas.toDataURL('image/jpeg', 1.0)
//         let PDF = new jsPDF({
//           orientation: "landscape",
//           unit: "px",
//         })
//         if (leftHeight < pageHeight) {
//           PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
//         } else {
//           while (leftHeight > 0) {
//             PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
//             leftHeight -= pageHeight
//             position -= 841.89
//             if (leftHeight > 0) {
//               PDF.addPage()
//             }
//           }
//         }
//         PDF.save(title + '.pdf');
//         resolve()
//       })
//     } catch (error) {
//       reject(error)
//     }
//   })
// }


export const html2pdf = (title: string, ref: any) => {
  const element = ref.current
  return new Promise((resolve) => {
    let w = element.clientWidth; //获取屏幕宽度
    let h = element.clientHeight; //获取屏幕高度
    let scale = 1;
    //一页pdf显示html页面生成的canvas高度;
    let canvas = document.createElement("canvas");
    canvas.width = w * scale;
    canvas.height = h * scale;
    canvas.style.width = w * scale + "px";
    canvas.style.height = h * scale + "px";
    canvas.getContext("2d")?.scale(scale, scale);
    let opt = {
      scale: scale, //添加scale 参数
      canvas: canvas, //自定义canvas
      logging: false,
      width: w, //canvas 宽度
      hieght: h, //canvas 高度
      useCORS: true, //开启跨域
    };
    html2canvas(element, opt)
      .then((canvas) => {
        var pdf = new jsPDF("p", "mm", "a4"); //A4纸，纵向
        var ctx = canvas.getContext("2d"),
          a4w = 190,
          a4h = 274, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
          imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
          renderedHeight = 0;

        while (renderedHeight < canvas.height) {
          var page = document.createElement("canvas");
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页 //用getImageData剪裁指定区域，并画到前面创建的canvas对象中

          page.getContext("2d")?.putImageData(
            ctx!.getImageData(
              0,
              renderedHeight,
              canvas.width,
              Math.min(imgHeight, canvas.height - renderedHeight)
            ),
            0,
            0
          );
          pdf.addImage(
            page.toDataURL("image/jpeg", 1.0),
            "JPEG",
            10,
            10,
            a4w,
            Math.min(a4h, (a4w * page.height) / page.width)
          ); //添加图像到页面，保留10mm边距
          renderedHeight += imgHeight;
          if (renderedHeight < canvas.height) {
            pdf.addPage(); //如果后面还有内容，添加一个空页
          }
        }
        resolve(pdf.output("datauristring", { filename: title }));
        pdf.save(title + '.pdf');
        // return pdf.output("datauristring", { filename: title });
        // document.getElementById("iframe123").src = pdf.output("datauristring");
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const regularData = {
  'email': /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
  phone: /^\d{1,}$/
}

export const verifyTencentCaptcha = () => {
  const { TencentCaptchaConfig = {} } = store.getState().PublicConfigReducer
  let local = localStorage.getItem("wapipay-website-locale");
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const captcha = new TencentCaptcha(TencentCaptchaConfig.TencentCaptchaAppId, function (res) {
      // console.log('res', res)
      if (res.ret === 0) {
        resolve(res)
      } else {
        reject(res)
      }
    }, {
      userLanguage: local || "zh-cn"
    });
    captcha.show()
  })
}

export function exportPdf(res: any, name: string) {
  const blob = new Blob([res]);
  const fileName = name + '.pdf';
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
}