import React, { FC, useEffect, useState, useMemo, useRef } from 'react'
import { Button, message, Spin, Form, Modal } from 'antd';
import { useTranslation } from "react-i18next";
import { history } from "@/route";
import { useMappedState } from "redux-react-hook";
import type { IState } from "@/redux/interface";
import styles from './QuickPay.module.scss'
import { accountApi } from '@/api'
import { isMobile, getCurrentMonetaryAttribute } from "@/utils/utils";
import { BackButton, NoDataCom } from '@/components';
import _ from 'lodash';
import { getToken } from '@/request';
import { useParams, useLocation } from "react-router";
import { QuickPayContext } from "./QuickPayContext";
import type { QuickPayPayMent } from "./QuickPayContext";
import BankTransfer from "@/assets/image/remittance/BankTransfer.png";
import Submitted from "@/assets/image/remittance/submitted.png";
import Amount from "./components/Amount/Amount";
import Recipient from "./components/Recipient/Recipient";
import Confirm from "./components/Confirm/Confirm";
import { RegistrationStatus, Status } from '@/api/auth/auth'

interface IQuickPayProps {
}

interface IMargin {
  [key: number]: number
}

declare type Placement = 'horizontal' | 'vertical'

const QuickPay: FC<IQuickPayProps> = (props) => {
  const { t } = useTranslation();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  let [detail, setDetail] = useState<accountApi.IAccountItem>()
  const params: { id: string } = useParams() || {}
  const accountId = params.id || ''
  const [finish, setFinish] = useState<boolean>(false)
  const rootRef = useRef(null);
  const [placement, setPlacement] = useState<Placement>('horizontal')
  const [step, setStep] = useState<number>(0)
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)
  const [payment, setPayment] = useState<QuickPayPayMent>({
    sendingAmount: '',
    sendingCurrency: 'KES',
    instAmount: '',
    exchangeRate: 0,
    instCurrency: 'CNY',
    paymentMethod: 3,
    deliveryMethod: 1,
    commission: 0,
    totalAmount: '',
    paymentTime: '',
    beneficiaryNo: '',
    recipientName: '',
    recipientId: 0,
    deliveryMethodName: 'Bank Transfer',
    deliveryMethodIcon: BankTransfer,
    paymentRate: 1,
    trnNo: '',
    id: undefined,
    formatSend: '',
    formatInst: '',
    inputType: 'number',
    productDescription: '',
    reason: 0,
    reasonName: '',
    source: 0,
    sourceName: '',
    agree: false,
    disabled: false,
    prePayData: {}
  })
  const monetaryAttribute = useMemo(() => {
    return getCurrentMonetaryAttribute(payment.paymentMethod)
  }, [payment.paymentMethod])

  useEffect(() => {
    setPlacement(isMobile() ? 'vertical' : 'horizontal')
    const token = getToken()
    if (token) {
      initData()
    }
  }, [])

  const initData = async () => {
    try {
      setLoading(true)
      const data = await accountApi.getAccountDetail(accountId)
      setDetail(data)
      payment.sendingCurrency = data.currency
      if (data.currency === 'CNY') {
        payment.instCurrency = 'KES'
      }
      if (location.state) {
        const {
          sendingCurrency = data.currency,
          instCurrency = data.currency === 'CNY' ? 'KES' : 'CNY',
          formatSend = '',
          formatInst = '',
          sendingAmount = '',
          instAmount = ''
        } = location.state as any
        payment.sendingCurrency = sendingCurrency
        payment.instCurrency = instCurrency
        payment.formatSend = formatSend
        payment.formatInst = formatInst
        payment.sendingAmount = sendingAmount
        payment.instAmount = instAmount
        payment.inputType = 'text'
        payment.totalAmount = sendingAmount
      }
      setPayment({
        ...payment
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const toNextStep = () => {
    window.scrollTo({ top: 0 })
    setStep(step + 1)
  }

  const createOrder = async () => {
    try {
      const data = await accountApi.accountQuickPay(accountId, {
        amount: payment.sendingAmount,
        recipientId: payment.recipientId,
        reason: payment.reason,
        source: payment.source,
        currency: payment.sendingCurrency,
        transCurrency: payment.instCurrency,
        description: payment.productDescription,
      })
      message.success(t('提交成功'))
      // history.push(`/order?id=${data.id}`)
      history.goBack()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const isDisabled = () => {
    const { instAmount, beneficiaryNo, agree, disabled } = payment;
    let d = false
    switch (step) {
      case 0:
        // d = !(Boolean(+instAmount) && isAllowCurrency())
        d = disabled || !(+instAmount)
        break
      case 1:
        d = !beneficiaryNo
        break
      case 2:
        d = !agree
        break
    }
    return d
  }

  if (!userInfoState.id) {
    return <div></div>
  }

  const margin: IMargin = {
    0: 180,
    1: 76,
    2: 220,
    3: 52
  }

  return (
    <Spin spinning={loading} tip="Loading">
      <BackButton />
      <QuickPayContext.Provider value={{
        confirmVisible, setConfirmVisible, step, setStep, payment, setPayment, finish, setFinish,
      }}>
        {finish ?
          <NoDataCom
            title={t('转账已提交！')}
            subtitle={t('感谢您选择WapiPay。我们正在努力处理您的转账事宜。')}
            btnTitle={t('检查转账状态')}
            imgSrc={Submitted}
            btnClick={() => {
              history.push('/order')
            }}
          /> :
          <div className={styles.root} ref={rootRef}>
            {step === 0 && <Amount placement={placement} detail={detail} />}
            {step === 1 && <Recipient setStep={toNextStep} />}
            {step === 2 && <Confirm detail={detail} />}
            <div className={styles.btn} style={{ marginBottom: isMobile() ? 0 : margin[step] }}>
              {step !== 1 && <Button
                type="primary"
                shape="round"
                className={`${styles.stepBtn}`}
                disabled={isDisabled()}
                loading={loading}
                onClick={() => {
                  switch (step) {
                    case 0:
                      if (!userInfoState?.id) {
                        history.push('/login')
                        return
                      }
                      if (userInfoState?.registrationStatus !== RegistrationStatus.Finished) {
                        message.warning(t('请完成注册后操作！'))
                        return
                      }
                      if (userInfoState?.status !== Status.certified) {
                        message.warning(t('对不起，您的账户正在审核中......'))
                        return
                      }
                      const sendingAmount = Number(payment.sendingAmount)
                      if (sendingAmount <= 0 || sendingAmount > (detail?.balance || 0)) {
                        message.warning(t("账户余额不足！"))
                        return
                      }
                      if (sendingAmount < 1) {
                        message.warning(t('快速转账金额低于最低限额，请调整'))
                        return
                      }
                      // @ts-ignore
                      const perMax = monetaryAttribute[payment.sendingCurrency]?.perMax
                      if (perMax && sendingAmount > perMax) {
                        message.warning(t('快速转账金额超出最高限额，请调整'))
                        return
                      }
                      setPayment({ ...payment, inputType: 'text' })
                      toNextStep()
                      break;
                    case 2:
                      if (!payment.reason) {
                        message.warning(t("请选择") + t("付款原因"))
                        return
                      }
                      if (!payment.source) {
                        message.warning(t("请选择") + t("资金来源"))
                        return
                      }
                      if (!payment.productDescription) {
                        message.warning(t("请填写") + t("描述"))
                        return
                      }
                      setLoading(true);
                      createOrder()
                      break;
                    default:
                  }
                }}>

                {t(step === 0 ? '选择收款人' : step === 2 ? "提交订单" : "下一步")}
              </Button>}
            </div>
          </div>
        }
      </QuickPayContext.Provider>
    </Spin>
  )
}

export default QuickPay
