import React, { FC, useMemo, useEffect, useState } from 'react'
import { Checkbox, Divider, Descriptions, Input, Select } from 'antd';
import moment from "moment";
import Exchange from "@/assets/image/order/exchange.png";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { useRemittanceContext } from "@/pages/MyAccounts/QuickPay/QuickPayContext";
import { formatCurrency } from "@/utils/utils";
import styles from "./Confirm.module.scss";
import { accountApi } from '@/api'
import { quickPayDeliveryOptions } from "@/utils/defaultData";
import type { IState } from "@/redux/interface";
import { useMappedState } from "redux-react-hook";
import { history } from "@/route";
import { fixed6ToFillZero } from "@/pages/HomePage/utils";

const { TextArea } = Input;
const { Option } = Select;

interface IConfirmProps {
  detail?: accountApi.IAccountItem;
}


const Confirm: FC<IConfirmProps> = (props) => {
  const { detail } = props
  const { t } = useTranslation();
  const { payment, setPayment } = useRemittanceContext();
  const { prePayData } = payment
  const { reasonOptions, sourceOfFund } = useMappedState(
    (state: IState) => state.PublicConfigReducer
  );

  const deliveryItem = useMemo(() => quickPayDeliveryOptions.find(i => i.value === payment.deliveryMethod), [payment.deliveryMethod])

  return (
    <div className={styles.confirmRoot}>

      <div className={cls(styles.detail, styles.infoDetail)}>
        <div className={cls(styles.label)}>
          <span>{t("付款信息")}</span>
        </div>
        <Divider />
        <Descriptions column={1}>
          <Descriptions.Item label={t("收款人")}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {payment.recipientName}
              </span>
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={t("汇款方法")}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {detail?.name}
              </span>
            </span>
            <span style={{ fontSize: 12, color: '#00B200' }}>{t("可用余额")}: {formatCurrency(detail?.balance || 0)} {detail?.currency}</span>
          </Descriptions.Item>
          <Descriptions.Item label={t("收款方式")}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {deliveryItem?.label}
              </span>
            </span>
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className={cls(styles.detail)}>
        <div className={cls(styles.label)}>
          <span>{t('付款原因')}</span>
        </div>
        <Divider />
        <Select
          style={{ width: '100%' }}
          optionLabelProp="label"
          dropdownMatchSelectWidth={false}
          placeholder={t('请选择付款原因')}
          getPopupContainer={(triggerNode: { parentNode: any; }) => triggerNode.parentNode || document.body}
          {...(payment.reason ? { value: payment.reason } : {})}
          onChange={(e: any) => {
            const i = (reasonOptions ?? []).findIndex((v: any) => e === v.id)
            setPayment({
              ...payment,
              reason: e,
              reasonName: reasonOptions[i]?.text,
            })
          }}
        >
          {
            reasonOptions.map((v: any) =>
              <Option
                value={v.confCode}
                key={v.id}
                label={v.text}
              >
                {v.text}
              </Option>)
          }
        </Select>
      </div>

      <div className={cls(styles.detail)}>
        <div className={cls(styles.label)}>
          <span>{t('资金来源')}</span>
        </div>
        <Divider />
        <Select
          style={{ width: '100%' }}
          optionLabelProp="label"
          dropdownMatchSelectWidth={false}
          placeholder={t('请选择资金来源')}
          getPopupContainer={(triggerNode: { parentNode: any; }) => triggerNode.parentNode || document.body}
          {...(payment.source ? { value: payment.source } : {})}
          onChange={(e: any) => {
            const i = (sourceOfFund ?? []).findIndex((v: any) => e === v.id)
            setPayment({
              ...payment,
              source: e,
              sourceName: sourceOfFund[i]?.text,
            })
          }}
        >
          {
            sourceOfFund.map((v: any) =>
              <Option
                value={v.confCode}
                key={v.id}
                label={v.text}
              >
                {v.text}
              </Option>)
          }
        </Select>
      </div>

      <div className={cls(styles.detail, styles.textarea)}>
        <div className={cls(styles.label)}>
          {/* <span>{`${t('描述')} (${t('可选')})`}</span> */}
          <span>{`${t('描述')}`}</span>
        </div>
        <Divider />
        <TextArea
          value={payment.productDescription}
          placeholder={`${t('产品描述')}...`}
          rows={5}
          onChange={(e) => setPayment({ ...payment, productDescription: e.target.value })}
        />
      </div>

      <div className={cls(styles.detail, styles.amountDetail)}>
        <div className={cls(styles.label)}>
          <span>{t("金额")}</span>
        </div>
        <Divider />
        <Descriptions column={1}>
          <Descriptions.Item label={t('汇款金额')}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {formatCurrency(payment.sendingAmount || 0)}
              </span>
              <span className={styles.currency}>{detail?.currency}</span>
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={t('收款金额')}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {formatCurrency(payment.instAmount || 0)}
              </span>
              <span className={styles.currency}>{payment.instCurrency}</span>
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={t('汇率')}>
            <span className={styles.desItem}>
              <span className={styles.value}>1 </span>
              <span className={styles.currency}>{`${payment.sendingCurrency}`}</span>
              <img src={Exchange} style={{ height: 18 }} alt="" />
              <span className={styles.value}>
                {payment.exchangeRate ? fixed6ToFillZero(payment.exchangeRate) : ''}
              </span>
              <span className={styles.currency}>{payment.instCurrency}</span>
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={t('服务费')}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {formatCurrency(prePayData?.serviceFee ?? 0)}
              </span>
              <span className={styles.currency}>{detail?.currency}</span>
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={t('总付款金额')}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {formatCurrency(prePayData?.total ?? 0)}
              </span>
              <span className={styles.currency}>{detail?.currency}</span>
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={t('对方可收到')}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {formatCurrency(prePayData?.transAmount ?? 0)}
              </span>
              <span className={styles.currency}>{detail?.currency}</span>
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={t('收款日期')}>
            <span className={styles.desItem}>
              <span className={styles.value}>
                {moment().format('DD-MM-YYYY')}
              </span>
            </span>
          </Descriptions.Item>
        </Descriptions>
      </div>

      <div className={styles.agree}>
        <Checkbox
          onChange={(c) => {
            payment.agree = c.target.checked
            setPayment({ ...payment })
          }}
          checked={payment.agree}
        >
          <span style={{ color: '#999' }}>{t("我同意Wapi Pay的条款和条件，包括：")}</span>
          <span
            className={styles.clickText}
            onClick={(e) => {
              e.preventDefault()
              window.open(window.location.origin + '/#/terms')
            }}
          >
            {t('使用条款')}
          </span>
          {t('及')}
          <span
            className={styles.clickText}
            onClick={(e) => {
              e.preventDefault()
              window.open(window.location.origin + '/#/privacyPolicy')
            }}
          >
            {t('隐私政策')}
          </span>
        </Checkbox>
      </div>

    </div>)
}

export default Confirm; 