import React, { FC, useState, useMemo, useEffect } from 'react'
import { Button, Select, Form, message, Input } from 'antd';
import styles from './index.module.scss'
import { Modal } from '@/components';
import { quickPayDeliveryOptions, quickPayCountryOptions, QuickPayDelivery } from '@/utils/defaultData';
import { DefaultInput, DefaultSelect, SelectPicture } from '@/components';
import { checkDisabled, regularData } from '@/utils/mainUtils';
import { useTranslation } from "react-i18next";
import { submitRecipient } from '@/api/recipient/recipient';
import _ from 'lodash';
// import moment from 'moment'
import { sendSms } from '@/api/register';
import { IState } from '@/redux/interface';
import { useMappedState } from 'redux-react-hook';
import { ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { checkVerificationCode, Nationality } from '@/api/auth/auth';
import { publicApi } from '@/api';
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import Card2 from '@/assets/image/recipient/card2.png';
import { verifyTencentCaptcha } from '@/utils/mainUtils'

const { Option } = Select;

interface IUpdateRecipientProps {
  setIsModalVisible: (flag: boolean) => void;
  isViewRecipient?: boolean;
  currentRecipient?: any;
  getList?: (current?: number, pageSize?: number) => void;
  pageSize?: number;
  onSuccess?: (firstName: string, lastName: string, beneficiaryNo: string) => void;
  receiveType?: number;
  country?: string;
}

const UpdateRecipient: FC<IUpdateRecipientProps> = (props) => {
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const bankData = useMappedState(
    (state: IState) => state.PublicConfigReducer.bankData
  );
  const { t, i18n } = useTranslation();
  const { setIsModalVisible, currentRecipient, getList, pageSize, onSuccess, isViewRecipient } = props;
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showVerifyCode, setShowVerifyCode] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [isCodeFocus, setIsCodeFocus] = useState<boolean>(false);
  const [haveCode, setHaveCode] = useState<boolean | string>(true);
  const [verifyCode, setVerifyCode] = useState();
  const [sendLoading, setSendLoading] = useState(false)
  const [sendSubmitLoading, setSendSubmitLoading] = useState(false)
  const [havePhone, setHavePhone] = useState<boolean>(true);
  const [isPhoneFocus, setIsPhoneFocus] = useState<boolean>(false);
  // const [genderOptions, setGenderOptions] = useState<any[]>([]);
  // const [relationshipOptions, setRelationshipOptions] = useState<any[]>([]);
  // const [accountTypeOptions, setAccountTypeOptions] = useState<any[]>([]);
  const timeCount = 60
  let timer = timeCount
  const isDisabled = currentRecipient ? true : false;
  const selectData = [
    { name: t("上传身份证/护照"), icon: Card2, key: 'idPhoto', src: '', val: '' },
  ]
  // const [fileData, setFileData] = useState<any>(selectData)
  // const [deliveryOptionsData, setDeliveryOptionsData] = useState<any[]>(quickPayDeliveryOptions);

  const onFinish = async (values: any) => {
    // const { receiveType, bankCode, smsCode, prefix, handPhone, country } = values || {};
    // if (showVerifyCode) {
    //   submitVerifyCode(smsCode)
    //   return
    // }
    // if (receiveType === 2 && country !== 2) {
    //   message.warning(t("抱歉，M-PESA不适用于此收款人的国家/地区。"))
    //   return;
    // }
    // try {
    //   setIsLoading(true);
    //   const { currency } = quickPayCountryOptions.find((item: any) => item.codeVal === country) || {};
    //   const formData = _.cloneDeep(values);
    //   formData.FomoCustRefNo = "CF885";
    //   formData.handPhone = `${prefix}${handPhone}`
    //   formData.idPhoto = fileData[0]?.val;
    //   formData.accountCurrency = currency;
    //   if (receiveType === 4) {
    //     formData.bankCode = "EWALIPAY";
    //     formData.bankName = "支付宝";
    //   }
    //   if (receiveType === 3 && country === 1) {
    //     const { bankName } = bankData.find((item: any) => item.bankCode === bankCode) || {};
    //     formData.bankName = bankName;
    //   }
    //   delete formData?.prefix;
    //   const { recipient } = await submitRecipient(formData);
    //   const { firstName, lastName, beneficiaryNo } = recipient ?? {}
    //   message.success(t('添加成功'));
    //   setIsModalVisible(false);
    //   onSuccess?.(firstName ?? '', lastName ?? '', beneficiaryNo ?? '')
    //   getList?.(1, pageSize ?? 8);
    //   setIsLoading(false);
    // } catch (error) {
    //   setIsLoading(false);
    // }
  }

  const getConfigData = async (name: string, setData: (arr: any[]) => void) => {
    const { list = [] } = await publicApi.getConfiguration({ name })
    setData(list);
  }

  useEffect(() => {
    // if (!isViewRecipient) {
    //   setShowVerifyCode(true)
    //   sendCode()
    // }
    // getConfigData('gender', setGenderOptions);
    // getConfigData('relationship', setRelationshipOptions);
    // getConfigData('accountType', setAccountTypeOptions);
    const { receiveType, country } = props
    if (country) {
      const item = quickPayCountryOptions.find(i => i.codeVal === +country)
      if (item) {
        form.setFieldsValue({ receiveType, country: item.codeVal })
        form.setFieldsValue({ prefix: item.value });
      }
    }
  }, [])

  // const submitVerifyCode = async (smsCode?: string | number) => {
  //   try {
  //     setSendSubmitLoading(true);
  //     const res = await checkVerificationCode({ mobile: userInfoState.userName, verificationCode: smsCode })
  //     if (res.code === 200) {
  //       // setCookie()
  //       setShowVerifyCode(false)
  //     } else {
  //       message.warning(t('无效验证码，请重试。'))
  //     }
  //     setSendSubmitLoading(false);
  //   } catch (error) {
  //     setSendSubmitLoading(false);
  //   }
  // }

  // const setCookie = () => {
  //   // @ts-ignore
  //   const expires = new Date().toGMTString ? `expires=${moment().add(1, 'd').toDate().toGMTString()}` : ''
  //   document.cookie = `verify=1;path=/;${expires}`
  // }

  // const sendCode = async () => {
  //   if (count) {
  //     return
  //   }
  //   const userName = userInfoState.userName
  //   if (userName) {
  //     try {
  //       const { randstr, ticket } = await verifyTencentCaptcha() as any
  //       setSendLoading(true);
  //       let params = {
  //         randstr,
  //         ticket,
  //         phone: Number(userName)
  //       }
  //       const res: any = await sendSms(params)
  //       setVerifyCode(res)
  //       setSendLoading(false);
  //       countTime()
  //     } catch (error) {
  //       setSendLoading(false);
  //     }
  //   }
  // }

  // const countTime = () => {
  //   timer = timeCount
  //   setCount(timer)
  //   const interval = setInterval(() => {
  //     if (timer <= 0) {
  //       clearInterval(interval)
  //     } else {
  //       timer--
  //       setCount(timer)
  //     }
  //   }, 1000)
  // }

  const onInputFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  // const returnValue = (str?: string | number) => {
  //   return str && str !== '-' ? Number(str) : '-'
  // }

  useEffect(() => {
    if (currentRecipient) {
      const {
        receiveType,
        countryId,
        firstName = '',
        countryCallingCode = '',
        mobile = '',
        email = '',
        address = '',
        bankName = '',
        bankBranch = '',
        bankAccountNumber = '',
        bankAccountMobile = '',
        province = '',
        city = '',
        postalCode = '',
      } = currentRecipient || {}
      const { value: receiveTypeValue = '' } = quickPayDeliveryOptions.find(item => item.value === receiveType?.code) || {};
      const formValues = {
        receiveType: receiveTypeValue,
        countryId,
        firstName,
        handPhone: `+${countryCallingCode}${mobile}`,
        email,
        address,
        bankName,
        bankBranch,
        bankAccountNumber,
        bankAccountMobile,
        province,
        city,
        postalCode,
      }
      form.setFieldsValue(formValues);
    }
  }, [currentRecipient])

  const onInputChange = (e: any, fn: (flag: boolean) => void) => {
    const { value } = e.target || {};
    fn(value ? true : false)
  }


  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ minWidth: 80 }}
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 250 }}
        disabled
      // onFocus={() => onInputFocus(setIsPhoneFocus, true)}
      // onBlur={() => onInputFocus(setIsPhoneFocus, false)}
      // onSelect={() => onInputFocus(setIsPhoneFocus, false)}
      >
        {
          quickPayCountryOptions.map((item: any) => (
            <Option
              value={item.value}
              key={`${item.value}`}
              label={
                <div className='flex-center'>
                  <img
                    src={item.icon}
                    className="country-logo"
                    style={{ width: 21, marginRight: 5 }}
                    alt=''
                  /> <span>{item.code}</span>
                </div>
              }
            >
              <div className='flex-center'>
                <img
                  src={item.icon}
                  className="country-logo"
                  style={{ width: 24, marginRight: 8 }}
                  alt=''
                /> {item.label} {item.code}
              </div>
            </Option>
          ))
        }
      </Select>
    </Form.Item>
  );

  return (
    <div className={`${styles.root} ${currentRecipient && styles.detailPage}`}>
      <Modal
        title={currentRecipient ? t("收款人详情") : t("添加收款人")}
        setIsModalVisible={setIsModalVisible}
        maskClosable={Boolean(currentRecipient)}
      >
        <div className='login-form-container'>
          <Form
            form={form}
            onFinish={onFinish}
            className="login-form"
            onValuesChange={() => setIsUpdate(!isUpdate)}
            initialValues={{ prefix: 254, country: 2 }}
          >
            {showVerifyCode ? <>
              {/* <div className='verify-wrap'>
                <div className='verify-tip'>{t('请输入您收到的6位验证码')} ****{userInfoState?.userName?.slice(userInfoState?.userName?.length - 4)}</div>
                <div className={`form-item-warp verification-code ${isCodeFocus && 'form-item-focus'} ${!haveCode && 'form-item-tips'} `}>
                  <Form.Item
                    name="smsCode"
                  >
                    <Input
                      maxLength={6}
                      placeholder={t('验证码')}
                      onChange={(e) => {
                        const { value } = e.target || {};
                        setHaveCode(value)
                      }}
                      onFocus={() => onInputFocus(setIsCodeFocus, true)}
                      onBlur={() => onInputFocus(setIsCodeFocus, false)}
                    />
                  </Form.Item>
                  <div className={`code-box ${count && 'code-time-box'}`} onClick={sendCode}>
                    {
                      !sendLoading ? count ? `${count}s${t("后重发")}` : t('发送验证码')
                        : (
                          <SyncOutlined spin />
                        )
                    }
                  </div>
                  {
                    !haveCode && (
                      <span className='tips'>
                        <ExclamationCircleOutlined /> {t('请输入您的验证码！')}
                      </span>
                    )
                  }
                </div>
                <div className={`${styles.btnWarp} ${styles.verifyButton}`}>
                  <Button disabled={!(String(haveCode).length === 6)} type="primary" htmlType="submit" loading={sendSubmitLoading} className='submit-button'>
                    {t("提交")}
                  </Button>
                </div>
              </div> */}
            </> : <>
              <div className={styles.formBox}>
                <div className={styles.formTitle}>{t("个人信息")}</div>
                <DefaultSelect
                  formItemName="receiveType"
                  placeholder={t("收款方式")}
                  formItemLabel={(Boolean(props.country) || isDisabled) ? t("收款方式") : ''}
                  disabled={Boolean(props.receiveType) || isDisabled}
                >
                  {
                    quickPayDeliveryOptions.map((item) => (
                      <Option
                        value={item.value}
                        key={item.value}
                      >
                        {item.label}
                      </Option>
                    ))
                  }
                </DefaultSelect>
                <SelectPicture
                  formItemName="countryId"
                  placeholder={t("收款方国家")}
                  formItemLabel={(Boolean(props.country) || isDisabled) ? t("收款方国家") : ''}
                  optionLabelProp="label"
                  optionData={quickPayCountryOptions}
                  valueKey="codeVal"
                  disabled={Boolean(props.country) || isDisabled || form.getFieldValue('receiveType') === 2}
                  onChange={(val: any) => {
                    // const { value } = quickPayCountryOptions.find((item: any) => item.codeVal === val) || {};
                    // form.setFieldsValue({ prefix: value });
                    // const newDeliveryOptions = _.cloneDeep(quickPayDeliveryOptions);
                    // newDeliveryOptions.forEach((item: any) => {
                    //   if (item.value === 2) {
                    //     item.disabled = val !== 2;
                    //   }
                    // })
                    // setDeliveryOptionsData(newDeliveryOptions);
                  }}
                />
                <DefaultInput
                  formItemName="firstName"
                  placeholder={t("收款人姓名")}
                  formItemLabel={isDisabled ? t("收款人姓名") : ''}
                  disabled={isDisabled}
                />
                {/* <div className={styles.nameWarp}>
                  <DefaultInput
                    formItemName="firstName"
                    placeholder={t("名字")}
                    formItemLabel={isDisabled ? t("名字") : ''}
                    tipsStyle={{ bottom: '-15px' }}
                    disabled={isDisabled}
                  />
                  <DefaultInput
                    formItemName="lastName"
                    placeholder={t("姓氏")}
                    tipsStyle={{ bottom: '-15px' }}
                    formItemLabel={isDisabled ? t("姓氏") : ''}
                    disabled={isDisabled}
                  />
                </div> */}
                {/* <DefaultInput
                  formItemName="handPhone"
                  placeholder={t("联系电话")}
                  formItemLabel={isDisabled ? t("联系电话") : ''}
                  disabled={isDisabled}
                /> */}
                <div className='form-item-warp'>
                  <Form.Item
                    name="handPhone"
                    label={isDisabled ? t("联系电话") : ''}
                    className={`${!havePhone && 'form-item-tips'} ${isPhoneFocus && 'form-item-focus'}`}
                  >
                    <Input
                      placeholder={t('联系电话')}
                      addonBefore={isDisabled ? '' : prefixSelector}
                      className='phone-input'
                      onChange={(e) => {
                        onInputChange(e, setHavePhone)
                      }}
                      disabled={isDisabled}
                      onFocus={() => onInputFocus(setIsPhoneFocus, true)}
                      onBlur={() => {
                        onInputFocus(setIsPhoneFocus, false)
                        const phone = form.getFieldValue('handPhone')
                        if (phone && phone.charAt(0) === '0') {
                          form.setFieldsValue({ handPhone: phone.slice(1) })
                        }
                      }}
                    />
                  </Form.Item>
                  {
                    !havePhone && (
                      <span className='tips'>
                        <ExclamationCircleOutlined /> {t('请输入您的联系电话！')}
                      </span>
                    )
                  }
                </div>
                <DefaultInput
                  formItemName="email"
                  placeholder={t("电子邮箱")}
                  formItemLabel={isDisabled ? t("电子邮箱") : ''}
                  regular={regularData.email}
                  disabled={isDisabled}
                  inputBlurFn={() => {
                    const email = form.getFieldValue('email')
                    if (email) {
                      form.setFieldsValue({ email: email.toLowerCase() })
                    }
                  }}
                />
                {(currentRecipient?.receiveType?.code === QuickPayDelivery.LocalBank) && (currentRecipient?.countryId === Nationality.China) && <>
                  <DefaultInput
                    formItemName="province"
                    placeholder={t("省")}
                    formItemLabel={isDisabled ? t("省") : ''}
                    disabled={isDisabled}
                  />
                  <DefaultInput
                    formItemName="city"
                    placeholder={t("城市")}
                    formItemLabel={isDisabled ? t("城市") : ''}
                    disabled={isDisabled}
                  />
                  <DefaultInput
                    formItemName="postalCode"
                    placeholder={t("邮政编码")}
                    formItemLabel={isDisabled ? t("邮政编码") : ''}
                    disabled={isDisabled}
                  />
                </>}
                <DefaultInput
                  formItemName="address"
                  placeholder={t("地址")}
                  formItemLabel={isDisabled ? t("地址") : ''}
                  // inputType='number'
                  disabled={isDisabled}
                />
                {
                  (currentRecipient?.receiveType?.code === QuickPayDelivery.LocalBank) && (
                    <>
                      <div className={styles.formTitle}>{t("银行信息")}</div>
                      <DefaultInput
                        formItemName="bankName"
                        placeholder={t("银行名称")}
                        formItemLabel={isDisabled ? t("银行名称") : ''}
                        disabled={isDisabled}
                      />
                      <DefaultInput
                        formItemName="bankBranch"
                        placeholder={t("银行支行")}
                        formItemLabel={isDisabled ? t("银行支行") : ''}
                        disabled={isDisabled}
                      />
                      <DefaultInput
                        formItemName="bankAccountNumber"
                        placeholder={t("银行账号")}
                        formItemLabel={isDisabled ? t("银行账号") : ''}
                        disabled={isDisabled}
                      />
                      {Boolean(currentRecipient?.bankAccountMobile) && <DefaultInput
                        formItemName="bankAccountMobile"
                        placeholder={t("开户时使用的电话号码")}
                        formItemLabel={isDisabled ? t("开户时使用的电话号码") : ''}
                        disabled={isDisabled}
                      />}
                    </>
                  )
                }
                {currentRecipient?.idPhoto && <>
                  <div className={styles.formTitle}>{t("文件")}</div>
                  <img src={currentRecipient.idPhoto} className={styles.pic} alt='' />
                </>}

              </div>
              <div className={styles.btnWarp}>
                {
                  isDisabled ? (
                    <Button type="primary" onClick={() => setIsModalVisible(false)} className='submit-button'>
                      {t("关闭")}
                    </Button>
                  ) : (
                    <Button disabled={form.getFieldValue('receiveType') === 2 ? !checkDisabled(form, 10) : regularData.email.test(form.getFieldValue('email')) ? !checkDisabled(form, form.getFieldValue('receiveType') === 3 ? 15 : 10) : true} type="primary" htmlType="submit" loading={isLoading} className='submit-button'>
                      {t("提交")}
                    </Button>
                  )
                }
                {/* <Button disabled={!checkDisabled(form, form.getFieldValue('receiveType') === 3 ? 10 : 7)} type="primary" htmlType="submit" loading={isLoading} className='submit-button'>
                {t("提交")}
                </Button> */}
              </div>
            </>}
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default UpdateRecipient
