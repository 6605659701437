import React, { FC, useEffect, useState, useRef, useMemo } from 'react'
import styles from "./Receipt.module.scss";
import { useTranslation } from "react-i18next";
import logoImg from "@/assets/components/nav-logo.svg";
import type { IState } from "@/redux/interface";
import { useMappedState } from "redux-react-hook";
import { Image } from "react-bootstrap";
import Exchange from "@/assets/image/order/exchange.png";
import Thanks from "@/assets/image/order/thanks.png";
import { Modal } from '@/components';
import { html2pdf } from '@/utils/mainUtils'
import { Button, message } from 'antd'
import { formatCurrency, upperCaseFristLetter, getTransactionMethodName } from "@/utils/utils";
import { fixed6ToFillZero } from "@/pages/HomePage/utils";

interface IReceiptProps {
  setVisible: (v: boolean) => void;
  data: any;
}

const Receipt: FC<IReceiptProps> = (props) => {
  const { t } = useTranslation();
  const { setVisible, data } = props;
  const isPayout = useMemo(() => data?.type === 'payout', [data])
  const elRef = useRef<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const { remitPurpose, sourceOfFund } = useMappedState(
    (state: IState) => state.PublicConfigReducer
  );
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const bankData = useMappedState(
    (state: IState) => state.PublicConfigReducer.bankData
  );
  const receipts: any[] = [
    {
      label: t('汇款人'),
      value: `${userInfoState?.firstName} ${userInfoState?.lastName}`
    },
    {
      label: t('收款人 ').trim(),
      value: data?.transUserFullName ?? ''
    },
    {
      label: t('银行名称 ').trim(),
      value: data?.recipient?.bankName ?? '',
      hide: data?.receiverMethod !== "bank"
    },
    {
      label: t('银行账号 ').trim(),
      value: data?.recipient?.bankCode ?? '',
      hide: data?.receiverMethod !== "bank"
    },
    {
      label: t('银行支行'),
      value: data?.recipient?.bankBranch ?? '',
      hide: data?.receiverMethod !== "bank"
    },
    {
      label: t('参考编号'),
      value: data?.refNo ?? ''
    },
    {
      label: t('提交日期'),
      value: data?.createTime ?? '',
    },
    {
      label: t('汇款方法').trim(),
      value: getTransactionMethodName({
        method: data?.method,
        type: data?.type,
        accountName: data?.accountName,
        transAccountName: data?.transAccountName,
        isPaymentMethod: true,
        isRefund: data?.isRefund,
      })
    },
    {
      label: t('收款方式').trim(),
      value: getTransactionMethodName({
        method: data?.receiverMethod,
        type: data?.type,
        accountName: data?.accountName,
        transAccountName: data?.transAccountName,
        isPaymentMethod: false,
        isRefund: data?.isRefund,
      })
    },
    {
      label: isPayout ? t('付款金额') : t('收款金额'),
      value: `${formatCurrency(data?.amount || 0)} ${data.currency}`
    },
    {
      label: t('余额'),
      value: `${formatCurrency(data?.balance || 0)} ${data.currency}`
    },
    {
      label: isPayout ? t('总付款金额') : t('总收款金额'),
      value: `${formatCurrency(data?.amount)} ${data?.currency}`
    },
    {
      label: t('汇率 ').trim(),
      value: data?.exchangeRateLocalCurrency === data?.exchangeRateForeignCurrency ? '1.00' : <>
        <span>1 {data?.exchangeRateLocalCurrency}</span>
        <Image style={{ margin: '0 6px', height: 14 }} src={Exchange} />
        <span>{`${fixed6ToFillZero(data?.exchangeRate) ?? ''} ${data?.exchangeRateForeignCurrency}`}</span>
      </>
    },
    {
      label: t('手续费'),
      value: `${formatCurrency(data?.serviceFee || 0)} ${data?.currency}`
    },
    {
      label: t('汇款金额').trim(),
      value: `${formatCurrency(data?.transAmount)} ${data?.transCurrency}`
    },
    {
      label: t('状态').trim(),
      value: upperCaseFristLetter(data?.status)
    },
  ]

  return (
    <Modal
      modalClassName={styles.modalWrap}
      title={t('收据回执')}
      setIsModalVisible={() => {
        setVisible(false)
      }}
    >
      <div className={styles.receiptRoot} ref={elRef}>
        <Image src={logoImg} className={styles.logoImg} />
        <div className={styles.description}>{t('感谢您的汇款。Wapi Pay为移动货币平台和银行账户提供最便捷的支付服务。如有问题 :')}</div>
        <div className={styles.link}>{t('访问')} <a href="https://global.wapipay.com/#/home">https://global.wapipay.com/#/home</a></div>
        <ul className={styles.receiptsContainer}>
          {receipts.map((item, index) => !item.hide && <li key={index} className={styles.receiptItem}>
            <div className={styles.receiptLabel}>{item.label}</div>
            <div className={styles.receiptValue}>{item.getValue ? item.getValue() : item.value}</div>
          </li>)}
        </ul>
        <Image src={Thanks} className={styles.thanksImg} />
        <div className={styles.footerTitle}>Wapi Pay</div>
        <div className={styles.footerDes}>
          <span>702, Westside Towers Lower Kabete</span>
          <a style={{ marginLeft: 36 }}>https://global.wapipay.com/#/order.</a>
        </div>
        <div className={styles.footerDes}>Road Westlands, Nairob</div>
      </div>
      <Button
        className={styles.confirmPrint}
        onClick={() => {
          setLoading(true)
          html2pdf(`Receipt-${data?.refNo}`, elRef)
            .then(() => {
              message.success(t('下载成功！'))
            })
            .catch(err => {
              message.error(`${t('下载失败！')}${String(err)}`)
            })
            .finally(() => {
              setLoading(false)
              setVisible(false)
            })
        }}
        loading={loading}
      >{t('下载')}</Button>
    </Modal>
  )
}

export default Receipt;