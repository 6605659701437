import React, { FC, useEffect, useRef, useMemo } from 'react'
import { Row, Col, Select, message } from 'antd';
import cls from "classnames";
import { useMappedState } from "redux-react-hook";
import type { IState } from "@/redux/interface";
import { useTranslation } from "react-i18next";
import Exchange from "@/assets/image/remittance/exchange.png";
import { exchangeType } from "@/pages/HomePage/HomePage";
import { CounterInput } from '@/components/Input/Input'
import useSize from "@/utils/useSize";
import { useRemittanceContext } from "@/pages/MyAccounts/QuickPay/QuickPayContext";
import { remittanceApi } from '@/api'
import { toFixed2, computedRate, fixed6ToFillZero } from "@/pages/HomePage/utils";
import { formatCurrency, getCurrentMonetaryAttribute } from "@/utils/utils";
import styles from './CurrencyExchange.module.scss'
import turnDown from "@/assets/components/turn-down.png";
import { quickPayExchangeRateOptions, QuickPayDelivery } from "@/utils/defaultData";

interface ICurrencyExchangeProps {
  onChange?: (t: string) => void;
}

const { Option } = Select;

const CurrencyExchange: FC<ICurrencyExchangeProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const { payment, setPayment } = useRemittanceContext();
  const ref1 = useRef<HTMLDivElement>(null);
  const { width: w1 } = useSize(ref1);
  const ref2 = useRef<HTMLDivElement>(null);
  const { width: w2 } = useSize(ref2);

  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );

  useEffect(() => {
    setPayment({
      ...payment,
      totalAmount: `${((+payment.commission ?? 0) + (+payment.sendingAmount ?? 0))}`
    })
  }, [payment.sendingAmount, payment.commission])

  useEffect(() => {
    if (!+payment.sendingAmount) return
    const instAmount = toFixed2((+payment.sendingAmount || 1) * payment.exchangeRate);
    setPayment({
      ...payment,
      instAmount,
      formatInst: formatCurrency(+instAmount)
    })
  }, [payment.exchangeRate])

  const getRate = async (sendingCurrency: string, instCurrency: string) => {
    if (sendingCurrency === instCurrency) {
      payment.exchangeRate = 1
      payment.beneficiaryNo = ''
      payment.recipientName = ''
      setPayment({
        ...payment,
      })
      return
    }
    const { list } = await remittanceApi.queryExchangeRate({
      localCurrency: sendingCurrency,
      foreignCurrency: instCurrency,
      custType: userInfoState.custType
    })
    const r = list?.[0]?.exchangeRate
    const exchangeRate = computedRate(r);
    if (!exchangeRate) {
      payment.exchangeRate = exchangeRate
      payment.beneficiaryNo = ''
      payment.recipientName = ''
      payment.instAmount = ''
      payment.sendingAmount = ''
      payment.formatSend = ''
      payment.formatInst = ''
      message.warning(t('汇率未设置'))
    } else {
      payment.exchangeRate = exchangeRate
      payment.beneficiaryNo = ''
      payment.recipientName = ''
    }
    setPayment({
      ...payment,
    })
  }


  const inputFormat = (type?: string) => {
    const { sendingAmount, instAmount } = payment
    if (type === 'focus') {
      setPayment({ ...payment, inputType: 'number' })
    } else {
      if (!sendingAmount) return;
      setPayment({
        ...payment,
        formatSend: formatCurrency(sendingAmount),
        formatInst: formatCurrency(instAmount),
        inputType: 'text',
      })
    }
  }

  useEffect(() => {
    getRate(payment.sendingCurrency, payment.instCurrency)
  }, [payment.sendingCurrency, payment.instCurrency])

  const optionItem = (v: any, className: string, isOption: boolean, disabled: boolean, i?: number) =>
    <div className={`flex-center ${className} ${disabled && styles.disabled} ${i === 5 && styles.group}`}>
      <img src={v.icon} alt="" />
      <span className={styles.label}>{v.label}</span>
      {isOption ? <span>{v.remarks}</span> : <img className={styles.turnDownIcon} src={turnDown} alt="" />}
    </div>

  const monetaryAttribute = useMemo(() => {
    return getCurrentMonetaryAttribute(payment.paymentMethod)
  }, [payment.paymentMethod])

  const computAmountRang = () => {
    // @ts-ignore
    const perMax = monetaryAttribute[payment.sendingCurrency]?.perMax
    return <div
      className={styles.balance}
    >
      {Boolean(perMax) && <>
        {t("快速转账范围")}: 1.00 - {formatCurrency(perMax)} {payment.sendingCurrency}
      </>}
    </div>
  }

  return (
    <div className={cls(styles.exchangeRoot, styles.flexBetween)}>
      <div className={styles.wrap}>
        <Row>
          <Col className={styles.inputWrap} xs={14} sm={15} ref={ref1}>
            <CounterInput
              placeholder={t('汇款金额')}
              value={payment.inputType === 'number' ? payment.sendingAmount : payment.formatSend}
              isControl={true}
              inputType={payment.inputType}
              onBlur={() => inputFormat('blur')}
              onFocus={() => inputFormat('focus')}
              onChange={(count) => {
                if (!payment.exchangeRate) return;
                if (!count) {
                  setPayment({ ...payment, instAmount: '', sendingAmount: '', totalAmount: '0', commission: 0 })
                  return;
                }
                const sendingAmount = count
                const instAmount = toFixed2((+count ?? 0) * payment.exchangeRate)
                setPayment({ ...payment, sendingAmount, instAmount })
              }}
            />
          </Col>
          <Col className={styles.selectWrap} xs={10} sm={9} ref={ref2}>
            <Select
              optionLabelProp="label"
              bordered={false}
              getPopupContainer={(triggerNode: { parentNode: any; }) => {
                return triggerNode.parentNode || document.body
              }}
              dropdownMatchSelectWidth={(w1 ?? 0) + (w2 ?? 0) + 2}
              listHeight={400}
              showArrow={false}
              {...(payment.sendingCurrency ? { value: payment.sendingCurrency } : {})}
              // onChange={(f: string) => {
              //   const e = quickPayExchangeRateOptions.filter(t => t.value === f)[0]
              //   const sendingCurrency = e.value;
              //   const instCurrency = e.value === payment.instCurrency ? exchangeType[e.value] : payment.instCurrency;
              //   setPayment({ ...payment, sendingCurrency, instCurrency })
              // }}
              disabled={true}
            >
              {
                quickPayExchangeRateOptions.map((v, i) =>
                  <Option
                    value={v.value}
                    key={v.value}
                    disabled={v.disabled}
                    label={optionItem(v, styles.opValue, false, false)}>
                    {optionItem(v, styles.opItem, true, !!v.disabled, i)}
                  </Option>)
              }
            </Select>
          </Col>
        </Row>
      </div>
      {computAmountRang()}
      <div className={styles.exchangeTips}>
        <img src={Exchange} alt="" />
        <span>{`1 ${payment.sendingCurrency} = ${payment.exchangeRate ? fixed6ToFillZero(payment.exchangeRate) : ''} ${payment.instCurrency}`}</span>
      </div>
      <div className={styles.wrap}>
        <Row>
          <Col className={styles.inputWrap} xs={14} sm={15} ref={ref1}>
            <CounterInput
              placeholder={t('收款金额')}
              value={payment.inputType === 'number' ? payment.instAmount : payment.formatInst}
              isControl={true}
              inputType={payment.inputType}
              onBlur={() => inputFormat('blur')}
              onFocus={() => inputFormat('focus')}
              onChange={(count) => {
                if (!payment.exchangeRate) return;
                if (!count) {
                  setPayment({ ...payment, instAmount: '', sendingAmount: '', totalAmount: '0', commission: 0 })
                  return;
                }
                const sendingAmount = toFixed2((+count ?? 0) / payment.exchangeRate)
                const instAmount = count
                setPayment({ ...payment, sendingAmount, instAmount })
              }}
            />
          </Col>
          <Col className={styles.selectWrap} xs={10} sm={9} ref={ref2}>
            <Select
              optionLabelProp="label"
              bordered={false}
              getPopupContainer={(triggerNode: { parentNode: any; }) => {
                return triggerNode.parentNode || document.body
              }}
              dropdownMatchSelectWidth={(w1 ?? 0) + (w2 ?? 0) + 2}
              listHeight={400}
              showArrow={false}
              {...(payment.instCurrency ? { value: payment.instCurrency } : {})}
              onChange={(f: string) => {
                const e = quickPayExchangeRateOptions.filter(t => t.value === f)[0]
                // const sendingCurrency = e.value === payment.sendingCurrency ? exchangeType[e.value] : payment.sendingCurrency;
                const instCurrency = e.value;
                setPayment({
                  ...payment,
                  // sendingCurrency,
                  instCurrency,
                  deliveryMethod: QuickPayDelivery.WapipayAccount,
                  deliveryMethodName: 'Wapipay account',
                })
                onChange?.(f)
              }}
            >
              {
                quickPayExchangeRateOptions.map((v, i) =>
                  <Option
                    value={v.value}
                    key={v.value}
                    // disabled={v.disabled || v.value === payment.sendingCurrency}
                    disabled={v.disabled}
                    label={optionItem(v, styles.opValue, false, false)}>
                    {optionItem(v, styles.opItem, true, !!v.disabled, i)}
                  </Option>)
              }
            </Select>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CurrencyExchange
