import React, { FC, useState, useMemo, useEffect } from 'react'
import { Button, Select, Form, message, Input, Spin } from 'antd';
import styles from './index.module.scss'
import { Modal } from '@/components';
import { quickPayRecipientNation, quickPayCountryOptions, quickPayDeliveryOptions, QuickPayDelivery } from '@/utils/defaultData';
import { DefaultInput, DefaultSelect, SelectPicture } from '@/components';
import { checkDisabled, regularData } from '@/utils/mainUtils';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { recipientApi } from '@/api'
import { isMobile } from '@/utils/utils';
import { Nationality } from '@/api/auth/auth'
import Card2 from '@/assets/image/recipient/card2.png';
import UploadComponent from '@/components/UploadComponent/UploadComponent';

const { Option } = Select;

interface IUpdateRecipientProps {
  setIsModalVisible: (flag: boolean) => void;
  isViewRecipient?: boolean;
  getList?: (current?: number, pageSize?: number) => void;
  pageSize?: number;
  onSuccess?: (firstName: string, lastName: string, beneficiaryNo: string) => void;
  receiveType?: number;
  country?: string;
  instCurrency: string;
}

const AddRecipient: FC<IUpdateRecipientProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { setIsModalVisible, getList, pageSize, onSuccess, isViewRecipient } = props;
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bankData, setBankData] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [idType, setIdType] = useState(1);
  const [isCny, setIsCny] = useState(props.instCurrency === 'CNY');
  const [fileData, setFileData] = useState<any>([
    { name: t('上传发票'), icon: Card2, key: 'idPhoto', src: '', val: '' },
  ])
  const isLocalBank = props.receiveType === QuickPayDelivery.LocalBank
  const isWapiAccount = props.receiveType === QuickPayDelivery.WapipayAccount

  const isDisabled = false;
  const onFinish = async (values: any) => {
    const { receiveType, bankCode, prefix, countryId, recipientUserId, phone } = values || {};

    if (receiveType === QuickPayDelivery.MPesa && countryId !== Nationality.Kenya) {
      message.warning(t("抱歉，M-PESA不适用于此收款人的国家/地区。"))
      return;
    }
    try {
      setIsLoading(true);
      const { label } = quickPayCountryOptions.find((item: any) => item.codeVal === countryId) || {};
      const { bankName } = bankData.find((item: any) => item.bankCode === bankCode) || {};
      const params: any = {
        receiveType: receiveType + '',
        firstName: values.fullName,
        // lastName: values.lastName,
        // fullName: values.fullName,
        countryCallingCode: prefix + '',
        country: label,
        countryId,
        idType: values.idType,
        idNumber: values.idNumber,
        email: values.email,
        address: values.address,
      }
      if (isWapiAccount) {
        const userItem = users.find(i => i.id === recipientUserId)
        if (!userItem || !userItem?.id) {
          message.warning(t('该用户未注册Wapi账号'))
          setIsLoading(false);
          return;
        }
        const handPhone = userItem.handPhone.replace(new RegExp('^' + prefix), '')
        params.recipientUserId = userItem.id
        params.phoneNumber = userItem.handPhone
        params.mobile = handPhone
      } else {
        params.phoneNumber = `${prefix}${phone}`
        params.mobile = `${phone}`
      }
      if (receiveType === QuickPayDelivery.LocalBank) {
        params.bankAccountNumber = values.accountNo
        params.bankName = bankName
        params.bankCode = values.bankCode
        params.bankAccountMobile = values.bankAccountMobile
        if (isCny) {
          params.province = values.province
          params.city = values.city
          params.postalCode = values.postalCode
          params.bankBranch = values.bankBranch
          if (fileData[0]?.val) {
            params.idPhoto = fileData[0].val
          }
        }
      }
      await recipientApi.addRecipient(params)
      setIsLoading(false);
      setIsModalVisible(false);
      getList?.(1, pageSize ?? 8);
      message.success(t('添加成功'));
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const { receiveType, country } = props
    if (country) {
      const item = quickPayCountryOptions.find(i => i.codeVal === +country)
      if (item) {
        form.setFieldsValue({
          receiveType,
          countryId: item.codeVal,
          prefix: item.value
        })
      }
    }
    if (receiveType === QuickPayDelivery.LocalBank) {
      getBanksList(props.country)
    }
  }, [])

  const getBanksList = async (countryCode?: string) => {
    const data = await recipientApi.queryBankList({ countryCode })
    setBankData(data)
  }

  const getUsersList = async (keyword: string) => {
    setSearchKeyword(keyword)
    if (!keyword) {
      setUsers([])
      return
    }
    try {
      setFetching(true)
      const prefix = form.getFieldValue('prefix')
      const searchRes = await recipientApi.searchUser({ keyword: `${prefix}${keyword}` })
      setUsers(searchRes || [])
      setFetching(false)
    } catch (error) {
      setFetching(false)
    }
  };

  const fetchUsers = _.debounce(getUsersList, 800);

  const disabledSubmit = () => {
    if (!regularData.email.test(form.getFieldValue('email'))) {
      return true
    }
    if (!isWapiAccount && !regularData.phone.test(form.getFieldValue('phone'))) {
      return true
    }
    switch (props.receiveType) {
      case QuickPayDelivery.LocalBank:
        if (!checkDisabled(form, isCny ? 16 : 12)) {
          return true
        }
        break;
      default:
        if (!checkDisabled(form, 9)) {
          return true
        }
    }
    return false
  }

  const onFileChange = async (e: any, record: any) => {
    const { response, status, originFileObj } = e.file;
    let url = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(originFileObj);
      reader.onload = () => resolve(reader.result);
    });
    if (status === 'done') {
      const { data = [] } = response || {}
      const newArr = [...fileData]
      const fileSrc = data && data.length ? data[0]?.filePath : '';
      if (!fileSrc) return;
      newArr.forEach((item: any) => {
        if (item.key === record.key) {
          item.val = fileSrc;
          item.src = url;
          form.setFieldsValue({ [item.key]: url });
        }
      })
      setFileData(newArr);
    }
  }

  return (
    // <div className={`${styles.root} ${currentRecipient && styles.detailPage}`}>
    <div className={`${styles.root}`}>
      <Modal
        title={t("添加收款人")}
        setIsModalVisible={setIsModalVisible}
        maskClosable={false}
      >
        <div className='login-form-container'>
          <Form
            form={form}
            onFinish={onFinish}
            className="login-form"
            onValuesChange={() => setIsUpdate(!isUpdate)}
          >
            <div className={styles.formBox}>
              <div className={styles.formTitle}>{t("个人信息")}</div>
              <DefaultSelect
                dropdownMatchSelectWidth={false}
                disabled={true}
                formItemLabel={isMobile() ? '' : t("收款方式")}
                formItemName="receiveType"
              >
                {
                  quickPayDeliveryOptions.map(v =>
                    <Option
                      value={v.value}
                      key={v.value}>
                      {v.label}
                    </Option>)
                }
              </DefaultSelect>
              <SelectPicture
                formItemName="countryId"
                placeholder={t("收款方国家")}
                formItemLabel={(Boolean(props.country) || isDisabled) && !isMobile() ? t("收款方国家") : ''}
                optionLabelProp="label"
                optionData={quickPayCountryOptions}
                valueKey="codeVal"
                onChange={(val: any) => {
                  const { value } = quickPayCountryOptions.find((item: any) => item.codeVal === val) || {};
                  form.setFieldsValue({ prefix: value });
                  form.resetFields(['bankCode', 'recipientUserId'])
                  setUsers([])
                  if (props.receiveType === QuickPayDelivery.LocalBank) {
                    getBanksList(val)
                  }
                  setIsCny(val === Number(quickPayRecipientNation.CNY))
                }}
              />
              <DefaultInput
                formItemName="fullName"
                placeholder={t("收款人姓名")}
                tipsStyle={{ bottom: '-15px' }}
                formItemLabel={isDisabled ? t("收款人姓名") : ''}
                disabled={isDisabled}
              />
              <div className={`form-item-warp ${styles.phoneWrap}`}>
                <Form.Item name="prefix" noStyle>
                  <Select
                    style={{ minWidth: 80 }}
                    optionLabelProp="label"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 250 }}
                  >
                    {
                      quickPayCountryOptions.map((item: any) => (
                        <Option
                          value={item.value}
                          key={`${item.value}`}
                          label={
                            <div className='flex-center'>
                              <img
                                src={item.icon}
                                className="country-logo"
                                style={{ width: 21, marginRight: 5 }}
                                alt=''
                              /> <span>{item.code}</span>
                            </div>
                          }
                        >
                          <div className='flex-center'>
                            <img
                              src={item.icon}
                              className="country-logo"
                              style={{ width: 24, marginRight: 8 }}
                              alt=''
                            /> {item.label} {item.code}
                          </div>
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                {isWapiAccount ? <DefaultSelect
                  formItemName="recipientUserId"
                  placeholder={t('收款人手机号码')}
                  formItemLabel={isDisabled ? t("收款人手机号码") : ''}
                  disabled={isDisabled}
                  otherSelectProps={{
                    onSearch: fetchUsers,
                    filterOption: false,
                    notFoundContent: fetching ? <Spin size="small" /> : <div>{searchKeyword ? t('该用户未注册Wapi账号') : t('没有找到')}</div>,
                    showSearch: true,
                    style: {
                      width: '100%'
                    }
                  }}
                  otherFormItemProps={{
                    noStyle: true
                  }}
                  containerClassName={styles.phoneItem}
                >
                  {
                    users.map((item: any) => (
                      <Option
                        value={item.id}
                        key={item.id}
                      >
                        {item.firstName} {item.lastName} ({item.handPhone})
                      </Option>
                    ))
                  }
                </DefaultSelect> : <DefaultInput
                  warpStyle={{ width: '100%' }}
                  formItemProps={{
                    noStyle: true
                  }}
                  formItemName="phone"
                  placeholder={t("收款人手机号码")}
                  formItemLabel={isDisabled ? t("收款人手机号码") : ''}
                  regular={regularData.phone}
                />}

              </div>
              <DefaultSelect
                dropdownMatchSelectWidth={false}
                placeholder={t("身份证类型")}
                formItemName="idType"
                onChange={(v) => {
                  setIdType(v)
                }}
              >
                <Option value={1}>
                  {t("身份证")}
                </Option>
                <Option value={2}>
                  {t("护照")}
                </Option>
              </DefaultSelect>
              <DefaultInput
                formItemName="idNumber"
                placeholder={isLocalBank ? (idType === 2 ? t("开户时使用的护照") : t("开户时使用的身份证号")) : (idType === 2 ? t("护照") : t("身份证号"))}
                formItemTips={`${t("请输入")}${idType === 2 ? t("护照") : t("身份证号")}`}
              // formItemLabel={t("身份证号")}
              />
              <DefaultInput
                formItemName="email"
                placeholder={t("电子邮箱")}
                // formItemLabel={t("电子邮箱")}
                regular={regularData.email}
                inputBlurFn={() => {
                  const email = form.getFieldValue('email')
                  if (email) {
                    form.setFieldsValue({ email: email.toLowerCase() })
                  }
                }}
              />
              {isLocalBank && isCny && <>
                <DefaultInput
                  formItemName="province"
                  placeholder={t("省")}
                  formItemLabel={isDisabled ? t("省") : ''}
                  disabled={isDisabled}
                />
                <DefaultInput
                  formItemName="city"
                  placeholder={t("城市")}
                  formItemLabel={isDisabled ? t("城市") : ''}
                  disabled={isDisabled}
                />
                <DefaultInput
                  formItemName="postalCode"
                  placeholder={t("邮政编码")}
                  formItemLabel={isDisabled ? t("邮政编码") : ''}
                  disabled={isDisabled}
                // option={true}
                />
              </>}
              <DefaultInput
                formItemName="address"
                placeholder={t("地址")}
              // formItemLabel={t("地址")}
              />
              {
                isLocalBank && (
                  <>
                    <div className={styles.formTitle}>{t("银行信息")}</div>
                    <DefaultSelect
                      formItemName="bankCode"
                      placeholder={t("银行名称")}
                      formItemLabel={isDisabled ? t("银行名称") : ''}
                      disabled={isDisabled}
                    >
                      {
                        bankData.map((item: any) => (
                          <Option
                            value={item.bankCode}
                            key={item.id}
                          >
                            {item.bankName}
                          </Option>
                        ))
                      }
                    </DefaultSelect>
                    <DefaultInput
                      formItemName="bankBranch"
                      placeholder={t("银行支行")}
                      formItemLabel={isDisabled ? t("银行支行") : ''}
                      disabled={isDisabled}
                    />
                    <DefaultInput
                      formItemName="accountNo"
                      placeholder={t("收款人银行账号")}
                      formItemLabel={isDisabled ? t("收款人银行账号") : ''}
                      disabled={isDisabled}
                      inputType="number"
                    // regular={/^\d+$/}
                    />
                    {isCny && <DefaultInput
                      formItemName="bankAccountMobile"
                      placeholder={t("开户时使用的电话号码")}
                      inputType="number"
                    // regular={/^\d+$/}
                    />}
                  </>
                )
              }
              {isLocalBank && isCny && <>
                <div className={styles.formTitle}>{t("文件")}({t("可选")})</div>
                <div className={styles.selectList}>
                  {
                    fileData.map((item: any) => (
                      <div
                        className={styles.selectLi}
                        key={item.name}
                      >
                        <UploadComponent
                          onChange={(e) => onFileChange(e, item)}
                          maxCount={1}
                          showUploadList={false}
                          disabled={isDisabled}
                        >
                          {
                            item.src ? (
                              <div className={styles.picWarp}>
                                <img src={item.src} className={styles.pic} alt='' />
                                {!isDisabled && (<div className={styles.text}>{t("重新上传")}</div>)}
                              </div>
                            ) : (
                              <img src={item.icon} className={styles.icon} alt='' />
                            )
                          }

                        </UploadComponent>
                        {/* <div className={styles.name}>{item.name}</div> */}
                      </div>
                    ))
                  }
                </div>
              </>}
            </div>
            <div className={styles.btnWarp}>
              <Button disabled={disabledSubmit()} type="primary" htmlType="submit" loading={isLoading} className='submit-button'>
                {t("提交")}
              </Button>
            </div>

          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default AddRecipient
