import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux'
import Reducers from "./redux/reducers";
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
import { StoreContext } from "redux-react-hook";
import { message } from 'antd'
import AppRoute from './route'
import '@/utils/i18n';

moment.locale('zh-cn')

export const store = createStore(Reducers)


message.config({
  top: 88,
  duration: 3,
  maxCount: 1,
  rtl: false,
})

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <AppRoute />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
