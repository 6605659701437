import React, { FC, useEffect, useState, useRef } from 'react'
import { Divider, message, Select } from 'antd';
import { useTranslation } from "react-i18next";
import { useRemittanceContext } from "@/pages/MyAccounts/QuickPay/QuickPayContext";
import { formatCurrency, deliveryMethodUsableOptions } from "@/utils/utils";
import CurrencyExchange from "../CurrencyExchange/CurrencyExchange";
import { quickPayDeliveryOptions, QuickPayDelivery } from '@/utils/defaultData';
import _ from 'lodash';
import styles from "./Amount.module.scss";
import { accountApi } from '@/api'
import { IPreQuickPayReS } from '@/api/account/account'
import moment from 'moment';
import { useParams } from "react-router";
import type { QuickPayPayMent } from "../../QuickPayContext";

interface IAmountProps {
  placement: string;
  detail?: accountApi.IAccountItem;
}

const { Option } = Select;

const Amount: FC<IAmountProps> = ({ placement, detail }) => {
  const { t } = useTranslation();
  const { payment, setPayment } = useRemittanceContext();
  const { prePayData } = payment
  const params: { id: string } = useParams() || {}
  const accountId = params.id || ''

  const [dOps, setDOps] = useState(quickPayDeliveryOptions)

  const getAmountData = async (sendingAmount: string, sendingCurrency: string, instCurrency: string) => {
    if (sendingAmount && sendingCurrency && instCurrency) {
      try {
        const data = await accountApi.preQuickPay(accountId, {
          amount: sendingAmount,
          currency: sendingCurrency,
          transCurrency: instCurrency
        })
        setPayment((p: QuickPayPayMent) => ({ ...p, disabled: false, prePayData: data }))
      } catch (error) {
        setPayment((p: QuickPayPayMent) => ({ ...p, disabled: true, prePayData: {} }))
      }
    }
  }

  const fetchDebounceAmountData = useRef(_.debounce(getAmountData, 800)).current;

  useEffect(() => {
    fetchDebounceAmountData(payment.sendingAmount, payment.sendingCurrency, payment.instCurrency);
  }, [payment.sendingAmount, payment.sendingCurrency, payment.instCurrency])

  useEffect(() => {
    return () => {
      fetchDebounceAmountData.cancel();
    };
  }, []);

  const paymentLabel = {
    service: t("服务费"),
    pay: t("总付款金额"),
    receive: t("对方可收到"),
    deliveryDate: t("收款日期"),
  }

  const returnAmount = (key: string) => {
    let amount = '';
    switch (key) {
      case 'service':
        amount = formatCurrency(prePayData?.serviceFee ?? 0);
        break;
      case 'pay':
        amount = formatCurrency(prePayData?.total ?? 0);
        break;
      case 'receive':
        amount = formatCurrency(prePayData?.transAmount ?? 0);
        break;
      case 'deliveryDate':
        amount = moment().format('DD-MM-YYYY')
        break;
      default:
    }
    return amount
  }

  const optionItem = (v: any, sts: React.CSSProperties) =>
    <div className={`flex-center`} style={sts}>
      {/* <div className={styles.opImg}>
        <img src={v.icon} style={{ height: 26 }} alt="" />
      </div> */}
      <span>{v.label}</span>
    </div>

  const returnPaymentEl = () => Object.entries(paymentLabel).map(([key, value], index) =>
    <div key={key} className={styles.payment}>
      <span className={styles.paymentKey} style={{ fontSize: index === 1 ? 16 : 14 }}>{value}</span>
      <span className={styles.paymentValue}>
        <span style={index === 1 ? { fontSize: 22, fontWeight: 600 } : {}}>{returnAmount(key)} </span>
        {key !== 'deliveryDate' && <span>{key === 'receive' ? payment.instCurrency : payment.sendingCurrency}</span>}
      </span>
    </div>)

  useEffect(() => {
    setDOps(_.cloneDeep(quickPayDeliveryOptions))
  }, [payment.instCurrency])

  return (
    <div className={`${styles.amountRoot} flex-column-center`}>
      <div className={styles.content}>
        <CurrencyExchange onChange={(t) => { }} />
        <div className={styles.paymentMethod}>
          <span>{t("汇款方法")}</span>
          <div>
            <div>{detail?.name}</div>
            <div>{t("可用余额")}: {formatCurrency(detail?.balance || 0)} {detail?.currency}</div>
          </div>
        </div>
        <div className={styles.method}>
          <span className={styles.label}>{t("收款方式")}</span>
          <Select
            optionLabelProp="label"
            dropdownMatchSelectWidth={false}
            getPopupContainer={(triggerNode: { parentNode: any; }) => triggerNode.parentNode || document.body}
            {...(payment.deliveryMethod ? { value: payment.deliveryMethod } : {})}
            onChange={(e: number) => {
              const current = dOps.filter(v => e === v.value)[0]
              // if (current.disabled) {
              //   message.warning(t(current.value === 2 ? 'M-PESA仅支持肯尼亚国家' : '即将推出...'))
              //   return
              // }
              setPayment({
                ...payment,
                deliveryMethod: e,
                deliveryMethodName: current.label,
                // deliveryMethodIcon: current.icon,
                beneficiaryNo: '',
                recipientName: ''
              })
            }}
          >
            {
              dOps.map(v => {
                let usableOptions = deliveryMethodUsableOptions(payment.instCurrency)
                return <Option
                  value={v.value}
                  key={v.value}
                  disabled={!usableOptions.includes(v.value)}
                  {...({ label: optionItem(v, { height: '100%' }) })}>
                  {optionItem(v, { color: 'inherit' })}
                </Option>
              })
            }
          </Select>
        </div>
        <Divider />
        {returnPaymentEl()}
      </div>
    </div>
  )
}

export default Amount;