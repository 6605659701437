import React, { FC, useEffect, useState, useRef } from 'react'
import { Button, message, Spin, Form, Select } from 'antd';
import { useTranslation } from "react-i18next";
import { history } from "@/route";
import { useMappedState, useDispatch } from "redux-react-hook";
import type { IState } from "@/redux/interface";
import styles from './MyAccounts.module.scss'
import AddIcon from '@/assets/image/accounts/add.svg';
import EditIcon from '@/assets/image/accounts/edit.svg';
import { accountApi } from '@/api'
import { formatCurrency } from "@/utils/utils";
import { countryOptions, deliveryOptions, myAccountsExchangeRateOptions } from "@/utils/defaultData";
import { Modal, DefaultInput, DefaultSelect, SelectPicture, NoDataCom } from '@/components';
import _ from 'lodash';
import { getToken } from '@/request';

const { Option } = Select;

interface IMyAccountsProps {
}

const MyAccounts: FC<IMyAccountsProps> = (props) => {
  const { t } = useTranslation();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const [loading, setLoading] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [list, setList] = useState<accountApi.IAccountItem[]>([])
  const [commissionsList, setCommissionsList] = useState<accountApi.IAccountItem[]>([])
  const [addAccountVisible, setAddAccountVisible] = useState<boolean>(false);
  const [editAccountVisible, setEditAccountVisible] = useState<boolean>(false);
  const [isPass, setIsPass] = useState<boolean>(false);
  const [isEditPass, setIsEditPass] = useState<boolean>(false);
  const [activeAccount, setActiveAccount] = useState<accountApi.IAccountItem>();
  const [currencyOptions, setCurrencyOptions] = useState(_.cloneDeep(myAccountsExchangeRateOptions))
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    const token = getToken()
    if (token) {
      getAllAccounts()
    }
  }, [])

  const getAllAccounts = async () => {
    try {
      setLoading(true)
      const data = await accountApi.queryAccounts()
      setCommissionsList(data.filter(i => i.type === "commission"))
      setList(data.filter(i => i.type !== "commission"))
      currencyOptions.forEach(i => {
        if (i.support) {
          i.disabled = data.some(c => c.currency === i.value)
        }
      })
      setCurrencyOptions([...currencyOptions])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onFinish = async (values: any) => {
    try {
      setModalLoading(true);
      await accountApi.addAccount(values)
      message.success(t("添加成功！"))
      form.resetFields()
      setAddAccountVisible(false)
      setIsPass(false)
      getAllAccounts()
      setModalLoading(false);
    } catch (error) {
      setModalLoading(false);
    }
  }

  const onEditFinish = async (values: any) => {
    if (!activeAccount) {
      return
    }
    try {
      setModalLoading(true);
      await accountApi.editAccount(activeAccount.id, values)
      message.success(t("修改成功！"))
      form.resetFields()
      setEditAccountVisible(false)
      setIsEditPass(false)
      getAllAccounts()
      setModalLoading(false);
    } catch (error) {
      setModalLoading(false);
    }
  }

  if (!userInfoState.id) {
    return <div></div>
  }

  return (
    <Spin spinning={loading} tip="Loading">
      <div className={styles.root}>
        <div className={styles.title}>{t("我的钱包")}</div>
        <div
          className={styles.addAccount}
          onClick={() => {
            setAddAccountVisible(true)
          }}
        >
          <img src={AddIcon} alt="" />
          <span>{t("新增钱包")}</span>
        </div>
        {Boolean(list?.length) ? list.map(item => {
          const countryItem = countryOptions.find(i => i.currency === item.currency)
          return <div
            className={styles.accountItem}
            key={item.id}
            onClick={() => {
              history.push(`/myAccounts/${item.id}`);
            }}
          >
            <div className={styles.topInfo}>
              <span>{item.name}</span>
              <img
                src={EditIcon}
                alt=""
                onClick={(e) => {
                  e.stopPropagation()
                  editForm.setFieldsValue({
                    name: item.name
                  })
                  setActiveAccount(item)
                  setEditAccountVisible(true)
                }}
              />
              <span className={styles.accountType}>{item.type ? item.type.charAt(0).toUpperCase() + item.type.slice(1) : ''}</span>
            </div>
            <div className={styles.bottomInfo}>
              <span>{formatCurrency(item.balance)}</span>
              {countryItem && <img src={countryItem.icon} alt="" />}
              <span className={styles.accountType}>{item.currency}</span>
            </div>
          </div>
        }) : <NoDataCom
          subtitle={t('你还没有钱包账户。')}
        ></NoDataCom>}


        {userInfoState.isAgent && Boolean(commissionsList?.length) && <>
          <div className={styles.title}>{t("佣金账户")}</div>
          {commissionsList.map(item => {
            const countryItem = countryOptions.find(i => i.currency === item.currency)
            return <div
              className={styles.accountItem}
              key={item.id}
              onClick={() => {
                history.push(`/myAccounts/${item.id}`);
              }}
            >
              <div className={styles.topInfo}>
                <span>{item.name}</span>
                <img
                  src={EditIcon}
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation()
                    editForm.setFieldsValue({
                      name: item.name
                    })
                    setActiveAccount(item)
                    setEditAccountVisible(true)
                  }}
                />
                <span className={styles.accountType}>{item.type ? item.type.charAt(0).toUpperCase() + item.type.slice(1) : ''}</span>
              </div>
              <div className={styles.bottomInfo}>
                <span>{formatCurrency(item.balance)}</span>
                {countryItem && <img src={countryItem.icon} alt="" />}
                <span className={styles.accountType}>{item.currency}</span>
              </div>
            </div>
          })}
        </>}
      </div>

      {addAccountVisible && <Modal
        title={t("新增钱包")}
        setIsModalVisible={setAddAccountVisible}
        maskClosable={true}
      >
        <div className='login-form-container'>
          <Form
            form={form}
            onFinish={onFinish}
            className="login-form"
            onValuesChange={(current, values) => {
              setIsPass(Boolean(String(values.name).trim() && values.currency))
            }}
          >
            <div className={styles.formBox}>
              <DefaultInput
                formItemName="name"
                placeholder={t("输入钱包名")}
                formItemLabel={t("输入钱包名")}
                maxLength={50}
              />
              <SelectPicture
                formItemName="currency"
                placeholder={t("选择货币")}
                formItemLabel={t("选择货币")}
                optionData={currencyOptions}
              />
            </div>
            <div className={styles.btnWarp}>
              <Button
                disabled={!isPass}
                type="primary"
                htmlType="submit"
                loading={modalLoading}
                className='submit-button'
              >
                {t("提交")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>}
      {editAccountVisible && <Modal
        title={t("编辑钱包")}
        setIsModalVisible={setEditAccountVisible}
        maskClosable={true}
      >
        <div className='login-form-container'>
          <Form
            form={editForm}
            onFinish={onEditFinish}
            className="login-form"
            onValuesChange={(current, values) => {
              setIsEditPass(Boolean(String(values.name).trim()))
            }}
          >
            <div className={styles.formBox}>
              <DefaultInput
                formItemName="name"
                placeholder={t("输入钱包名")}
                formItemLabel={t("输入钱包名")}
                maxLength={50}
              />
            </div>
            <div className={styles.btnWarp}>
              <Button
                disabled={!isEditPass}
                type="primary"
                htmlType="submit"
                loading={modalLoading}
                className='submit-button'
              >
                {t("提交")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>}
    </Spin>
  )
}

export default MyAccounts
