import $request from '@/request';

export interface IRecipientParams {
  Status: number;
  receiveType?: number;
  nationality?: string;
  country?: string;
  pageSize?: number;
  pageNum: number;
  name?: string;
}

export interface IQuickPayParams {
  pageSize?: number;
  pageNum: number;
  firstName?: string;
  receiveType?: number;
  countryId?: number;
}

export interface IRecipientRow {
  accountCurrency: null | string;
  accountName: null | string;
  accountNo: string;
  accountType: number;
  address1: null | string;
  bankBranch: string;
  bankBranchCode: null | number
  bankCode: string;
  bankName: string;
  bankReference: null | string;
  beneficiaryId: null | number;
  beneficiaryNo: string;
  birthDay: null | string;
  chineseName: null | string;
  city: null | string;
  cityId: null | number;
  comments: string;
  country: string;
  createBy: string;
  createTime: string;
  dateOfBirth: null | string;
  district: null | string;
  email: string;
  firstName: string;
  fomoBeneRefNo: null | number;
  fomoCustRefNo: null | number;
  gender: string;
  handPhone: string;
  id: number;
  idPhoto: null | string;
  idProof: string;
  identificationExpiryDate: null | string;
  identificationNumber: null | string;
  identificationType: string;
  lastName: string;
  middleName: null | string;
  nationality: string;
  occupation: null | string;
  params: any;
  passNo: null | number;
  pd: null | string;
  postalCode: null | number;
  processDateTime: string;
  province: null | string;
  receiveType: number;
  relationshipToCustomer: string;
  remark: null | string;
  remarkName: null | string;
  resultCode: number;
  searchValue: null | string;
  snippet: null | string;
  status: number;
  swiftCode: null | number;
  thirdPartId: null | number;
  typeOfPersonal: number;
  updateBy: string;
  updateTime: null | string;
  userId: number;
  countryId: number;
  phoneNumber: string;
}

export interface IRecipientData {
  total: number;
  code: number;
  list: IRecipientRow[];
  msg?: string;
}

export const queryRecipients = (d: IRecipientParams): Promise<IRecipientData> => {
  return $request.post('/remit-service/remit/beneficiary/list/page', {
    Status: d.Status,
    receiveType: d.receiveType,
    nationality: d.nationality,
    country: d.country,
    pd: {
      pageSize: d.pageSize ?? 10,
      pageNum: d.pageNum,
      orderByColumn: 'create_time',
      isAsc: 'desc'
    },
    name: d.name
  })
}

export const submitRecipient = (data: any): Promise<any> => {
  return $request.post('/remit-service/remit/beneficiary/add', data)
}

export const getBankList = (): Promise<any> => {
  return $request.post('/remit-service/remit/bankInfo/list', { "pd": { "pageNum": 1, "pageSize": 1000 } })
}

// export const deleteRecipient = (id: number | string, userId: number | string): Promise<any> => {
//   return $request.post(`/remit-service/remit/beneficiary/remove/${id}`, {}, {
//     headers: { userId }
//   })
// }
export const deleteRecipient = (id: number | string): Promise<any> => {
  return $request.delete(`/remit-service/remit/recipients/${id}`)
}


export const quickPayQueryRecipients = (data: IQuickPayParams): Promise<IRecipientData> => {
  return $request.post('/remit-service/remit/recipients/list/page', data)
}

export const queryBankList = (params: any): Promise<any[]> => {
  return $request.get('/remit-service/remit/bank_info', {
    params
  })
}

export const addRecipient = (data: any): Promise<any> => {
  return $request.post('/remit-service/remit/recipients', data)
}

export const searchUser = (data: any): Promise<any> => {
  return $request.post('/remit-service/remit/user/searchByKeyword', data)
}

export const getTemporaryToken = (): Promise<{ data: string }> => {
  return $request.get(`remit-service/remit/user/temporary_token`)
}

export const invitationPageSearchUser = (data: any, tmpToken: string): Promise<any> => {
  // @ts-ignore
  return $request.post('/remit-service/remit/user/tmp_searchByKeyword', data, { tmpToken })
}

export const invitationAddRecipient = (data: any, tmpToken: string): Promise<any> => {
  // @ts-ignore
  return $request.post('/remit-service/remit/recipients/tmp_create', data, { tmpToken })
}