import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from "react-i18next";
import { message, Pagination, Input, Spin, Form, Select, Button } from 'antd';
import Add from "@/assets/image/remittance/Add.png";
import Email from "@/assets/image/remittance/email.png";
import Phone from "@/assets/image/remittance/phone.png";
import Search from "@/assets/image/remittance/search.svg";
import cls from "classnames";
import AddRecipient from "../AddRecipient";
import { useRemittanceContext } from "@/pages/MyAccounts/QuickPay/QuickPayContext";
import { recipientApi } from '@/api'
import { useMappedState } from "redux-react-hook";
import { IState } from "@/redux/interface";
import type { IRecipientRow } from "@/api/recipient/recipient";
import styles from "./Recipient.module.scss";
import _ from 'lodash'
import { quickPayCountryOptions, quickPayRecipientNation } from '@/utils/defaultData';
import { DefaultInput, DefaultSelect, Modal } from '@/components';
import InviteLink from '@/pages/Recipient/components/InviteLink'
import { LinkOutlined } from '@ant-design/icons';

const { Option } = Select;

interface IRecipientProps {
  setStep?: () => void
}

export interface Ct {
  value: string;
  icon: string;
}

export interface CountryType {
  '1': Ct;
  '2': Ct;
  '3': Ct;
  [key: string]: any
}

const Recipient: FC<IRecipientProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recipients, setRecipients] = useState<IRecipientRow[]>([]);
  const [loading, setLoading] = useState(false);
  const { payment, setPayment } = useRemittanceContext();
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [recipientForm] = Form.useForm();
  const [isPass, setIsPass] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 6,
    total: 0,
    name: '',
    showTotal: (total: any) => `Total ${total} recipient`,
  });
  const [linkVisible, setLinkVisible] = useState(false);
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const getRecipients = async () => {
    try {
      setLoading(true)
      const { current: pageNum = 1, pageSize = 6, name } = pagination
      const { total, list } = await recipientApi.quickPayQueryRecipients({
        pageSize, pageNum, firstName: name, receiveType: payment.deliveryMethod, countryId: +quickPayRecipientNation[payment.instCurrency]
      })
      setRecipients(list ?? [])
      setPagination({ ...pagination, total })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getRecipients()
  }, [pagination.current, pagination.name])

  useMemo(() => {
    document.body.style.overflow = isModalVisible ? 'hidden' : 'auto'
  }, [isModalVisible])

  const handleChange = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    pagination.current = 1
    pagination.name = e.target.value
    setPagination({ ...pagination })
  }, 500)

  const addRecipientFinish = async (values: any) => {
    try {
      setModalLoading(true);
      setModalLoading(false);
      setFormVisible(false);
    } catch (error) {
      setModalLoading(false);
    }
  }

  return (
    <Spin spinning={loading} tip="Loading">
      <div className={styles.recipientRoot}>
        <div className={styles.title}>{t("请选择收款人")}</div>
        <div className={styles.select} onClick={() => { setIsModalVisible(true) }}>
          <img src={Add} alt="" />
          <span>{t("添加收款人")}</span>
        </div>
        <div className={styles.linkBtnWarp} onClick={() => {
          setLinkVisible(true)
        }}>
          <LinkOutlined className={styles.linkIconClass} /><span>{t("通过链接邀请")}
          </span>
        </div>
        <div className={styles.tips}>{t("从已有收款人选择")}</div>
        <Input
          prefix={<img src={Search} />}
          placeholder='Search people'
          size='large'
          className={styles.inputSearch}
          onChange={handleChange}
        />
        {
          recipients.map((v, i) => {
            const countryItem = quickPayCountryOptions.find(c => c.codeVal === v.countryId)
            return <div
              key={i}
              className={cls(styles.recipient, v.beneficiaryNo === payment.beneficiaryNo ? styles.active : '')}
              onClick={() => {
                setPayment({
                  ...payment,
                  recipientName: `${v.firstName ?? ''} ${v.lastName ?? ''}`,
                  recipientId: v.id,
                  beneficiaryNo: v.beneficiaryNo
                })
                setStep?.()
              }}
            >
              <div className={styles.leftInfo}>
                <div className={styles.name}>{`${v.firstName ?? ''} ${v.lastName ?? ''}`}</div>
                <div className={styles.center}>
                  <img src={Email} alt="" />
                  <span>{v.email ?? ''}</span>
                </div>
                <div className={styles.center}>
                  <img src={Phone} alt="" />
                  <span>+{v.phoneNumber ?? ''}</span>
                </div>
              </div>
              <div className={styles.rightInfo}>
                <img src={countryItem?.icon} alt="" />
                <span>{countryItem?.label}</span>
              </div>
            </div>
          })
        }
        {pagination.total > 4 && <div className={styles.pageSizeBox}>
          <Pagination
            onChange={(current: number) => {
              setPagination({ ...pagination, current })
            }}
            {...pagination}
          />
        </div>}
        {isModalVisible && <AddRecipient
          instCurrency={payment.instCurrency}
          setIsModalVisible={setIsModalVisible}
          pageSize={6}
          getList={getRecipients}
          receiveType={payment.deliveryMethod}
          country={quickPayRecipientNation[payment.instCurrency]}
          onSuccess={(firstName: string, lastName: string, beneficiaryNo: string) => {
            setPayment({
              ...payment,
              recipientName: `${firstName ?? ''} ${lastName ?? ''}`,
              beneficiaryNo: beneficiaryNo
            })
          }}
        />}
        {formVisible && <Modal
          title={t("添加付款方式")}
          setIsModalVisible={setFormVisible}
          maskClosable={true}
        >
          <div className='login-form-container'>
            <Form
              form={recipientForm}
              onFinish={addRecipientFinish}
              className="login-form"
              onValuesChange={(current, values) => {
                setIsPass(Boolean(values.mobile && values.type))
              }}
            >
              <div className={styles.formBox}>
                <DefaultInput
                  formItemName="mobile"
                  // formItemLabel={t("Wapi电话号码/用户ID")}
                  maxLength={50}
                  inputType="number"
                  placeholder={t("Wapi电话号码/用户ID")}
                  addonBefore={<span>+254</span>}
                />
                <DefaultInput
                  formItemName="firstName"
                  // formItemLabel={t("收款人姓名")}
                  maxLength={50}
                  placeholder={t("收款人姓名")}
                  addonBefore={<span>+254</span>}
                />
                <DefaultSelect
                  formItemName="type"
                  formItemLabel={t("汇款方法")}
                >
                  <Option
                    value='m-pesa'
                  >
                    M-PESA
                  </Option>
                </DefaultSelect>
                <DefaultInput
                  formItemName="mobile"
                  formItemLabel={t("输入M-pesa电话号码")}
                  maxLength={50}
                  inputType="number"
                  placeholder={t("手机号码")}
                  addonBefore={<span>+254</span>}
                />
              </div>
              <div className={styles.btnWarp}>
                <Button
                  disabled={!isPass}
                  type="primary"
                  htmlType="submit"
                  loading={modalLoading}
                  className='submit-button'
                >
                  {t("提交")}
                </Button>
              </div>
            </Form>
          </div>
        </Modal>}
        {linkVisible && <InviteLink setVisible={setLinkVisible} />}
      </div>
    </Spin>
  )
}


export default Recipient;