import React, { FC } from 'react'
import styles from './index.module.scss'
import moment from 'moment';

interface IFooterComponentProps {
	className?: string;
}

const FooterComponent: FC<IFooterComponentProps> = (props) => {
	const { className } = props;

	return (
		<div className={`${styles.footerCom} ${className}`}>
			2019-{moment().format('YYYY')} © Wapi Pay All rights reserved
		</div>
	)
}

export default FooterComponent
