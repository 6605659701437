import React, { useEffect, useState } from 'react'
import { message, Spin } from 'antd';
import { Modal } from '@/components';
import { useTranslation } from "react-i18next";
import copyCom from "copy-to-clipboard";
import styles from './index.module.scss'
import { recipientApi } from '@/api'
import { useMappedState } from "redux-react-hook";
import { IState } from "@/redux/interface";
import { baseWebURL } from "@/request";

interface IInviteLinkProps {
  setVisible: (b: boolean) => void;
}

const InviteLink = (props: IInviteLinkProps) => {
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const fullName = `${userInfoState?.firstName}${userInfoState?.lastName ? `-${userInfoState?.lastName}` : ``}`;
  const { setVisible } = props
  const { t } = useTranslation();
  const [slugLink, setSlugLink] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCopy = (value: string) => {
    copyCom(value);
    message.success(t("链接已复制！"))
  }

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      setLoading(true);
      const res = await recipientApi.getTemporaryToken()
      setSlugLink(`${baseWebURL}#/invitation?slug=${res}&fullName=${fullName}`)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Spin spinning={loading} tip="Loading">
      <div className={styles.root}>
        <Modal
          title={t("通过链接邀请")}
          setIsModalVisible={setVisible}
          maskClosable={true}
          modalClassName={styles.linkModal}
        >
          <div className={styles.linkWarp}>
            <span className={styles.linkTitle}>{t("请将下面的链接分享给收款人（点击自动复制）")}</span>
            <span className={styles.linkText} onClick={() => handleCopy(slugLink)}>
              {slugLink}
            </span>
          </div>
        </Modal>
      </div>
    </Spin>
  )
}

export default InviteLink;
