import React, { FC, useEffect, useState, useMemo } from 'react'
import { Timeline, Row, Col, Button, Modal } from 'antd';
import { LeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { history } from "@/route";
import Finish from "@/assets/image/order/finish.png";
import Fail from "@/assets/image/order/fail.png";
import Phone from "@/assets/image/order/phone.png";
import cls from "classnames";
import { localFormat } from '@/utils/moment';
import Exchange from "@/assets/image/order/exchange.png";
import S1 from "@/assets/image/order/step1.png";
import S2 from "@/assets/image/order/step2.png";
import S3 from "@/assets/image/order/step3.png";
import S4 from "@/assets/image/order/step4.png";
import S5 from "@/assets/image/order/finish.png";
import { Month, formatCurrency, upperCaseFristLetter, getTransactionMethodName } from "@/utils/utils";
import { fixed6ToFillZero } from "@/pages/HomePage/utils";
import styles from "./TransactionDetail.module.scss";
import { useTranslation } from "react-i18next";
import { accountApi } from '@/api'
import { useParams } from "react-router";
import { BackButton } from '@/components';
import Receipt from './components/Receipt/Receipt'
import { getToken } from '@/request';


export interface Status {
  'Failed': string;
  'Waiting': string;
  'Processing': string;
  'Complete': string;
  'Cancelled': string;
  CNY: string;
  USD: string;
  KES: string;
  [key: string]: string;
}


interface IPcDetailProps {
}

interface IDefault {
  finish?: boolean;
  icon?: string;
  label?: string;
  successIcon?: string;
}

interface IProgress extends IDefault {
  createTime?: string;
}

const PcDetail: FC<IPcDetailProps> = () => {
  const { t } = useTranslation();
  const [detail, setDetail] = useState<any>({})
  const isPayout = useMemo(() => detail?.type === 'payout', [detail])
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [receiptVisible, setReceiptVisible] = useState<boolean>(false);
  const params: { id: string, recordId: string } = useParams() || {}
  const accountId = params.id || ''
  const recordId = params.recordId || ''

  const statusValue: Record<string, { label: string, icon: string }> = {
    '1': {
      label: '等待汇款',
      icon: S1
    },
    '2': {
      label: '支付检验',
      icon: S2
    },
    '3': {
      label: '已收到款项',
      icon: S3
    },
    '4': {
      label: '汇款处理中',
      icon: S4
    },
    '5': {
      label: '交易成功',
      icon: S5
    },
  }

  useEffect(() => {
    const token = getToken()
    if (token) {
      getDetails()
    }
  }, [])

  const getDetails = async () => {
    if (!recordId) return;
    const data = await accountApi.getTransactionDetail(recordId);
    setDetail({
      ...data,
      createTime: data.createdAt ? localFormat(data.createdAt, true) : '',
    })
    const { progressList } = data
    const p: IProgress[] = []
    progressList.forEach((v: IDefault, i) => {
      let item: IProgress = v
      const cr = progressList?.[i]
      const pr = progressList?.[i + 1]
      if (cr) {
        const { createdAt, type = {} } = cr
        const month = Month(localFormat(createdAt, 'MM') as string)
        const day = localFormat(createdAt, 'DD')
        item = {
          ...v,
          createTime: `${month} ${day}`,
          label: statusValue[type?.code]?.label || type?.name || '-',
          icon: statusValue[type?.code]?.icon || S2,
          finish: !Boolean(pr)
        }
      }
      p.push(item)
    })
    setProgress(p)
  };

  const ReceiptCard = () =>
    <div className={styles.detail}>
      <div className={styles.base}>
        {!detail?.isRefund && <div className={styles.baseItem}>
          <span>{t('收款人 ').trim()}：</span>
          <span>{detail?.transUserFullName}</span>
        </div>}
        <div className={styles.baseItem}>
          <span>{t('参考编号')}：</span>
          <span>{detail?.refNo}</span>
        </div>
        <div className={styles.baseItem}>
          <span>{t('提交日期').trim()}：</span>
          <span>{detail?.createTime}</span>
        </div>
      </div>
      <div className={styles.send}>
        {!detail?.isRefund && <>
          <div className={`flex-between ${styles.sendItem}`}>
            <span>{t('汇款方法')}</span>
            <span className='flex-center'>{getTransactionMethodName({
              method: detail?.method,
              type: detail?.type,
              accountName: detail?.accountName,
              transAccountName: detail?.transAccountName,
              isPaymentMethod: true,
              isRefund: detail?.isRefund,
            })}</span>
          </div>
          <div className={`flex-between ${styles.sendItem}`}>
            <span>{t('收款方式')}</span>
            <span className='flex-center'>{getTransactionMethodName({
              method: detail?.receiverMethod,
              type: detail?.type,
              accountName: detail?.accountName,
              transAccountName: detail?.transAccountName,
              isPaymentMethod: false,
              isRefund: detail?.isRefund,
            })}</span>
          </div>
        </>}
        <div className={`flex-between ${styles.sendItem}`}>
          <span>{isPayout ? t('付款金额') : t('收款金额')}</span>
          <span className='flex-center'>
            {detail?.type === 'collection' ? <span>
              {formatCurrency(detail?.amount || 0)} {detail.currency}
            </span> : <span style={{ color: '#FF5C00' }}>-{formatCurrency(detail?.amount || 0)} {detail?.currency}</span>}
          </span>
        </div>
        <div className={`flex-between ${styles.sendItem}`}>
          <span>{t('余额')}</span>
          <span className='flex-center'>{formatCurrency(detail?.balance || 0)} {detail?.currency}</span>
        </div>
      </div>
      <div className={styles.status}>
        <div className={`flex-between ${styles.statusItem} ${styles.majoy}`}>
          <span className={styles.label}>{isPayout ? t('总付款金额') : t('总收款金额')}</span>
          {`${formatCurrency(detail?.amount)} ${detail?.currency}`}
        </div>
        {!detail?.isRefund && <>
          <div className={`flex-between ${styles.statusItem}`}>
            <span className={styles.label}>{t('汇率')}</span>
            <span className='flex-center'>
              {detail?.exchangeRateLocalCurrency === detail?.exchangeRateForeignCurrency ? '1.00' : <>
                <span>1 {detail?.exchangeRateLocalCurrency}</span>
                <img src={Exchange} alt="" />
                <span>{`${fixed6ToFillZero(detail?.exchangeRate) ?? ''} ${detail?.exchangeRateForeignCurrency}`}</span>
              </>}
            </span>
          </div>
          <div className={`flex-between ${styles.statusItem}`}>
            <span className={styles.label}>{t('手续费')}</span>
            {`${formatCurrency(detail?.serviceFee || 0)} ${detail?.currency}`}
          </div>
        </>}
        <div className={`flex-between ${styles.statusItem}`}>
          <span className={styles.label}>{t('汇款金额')}</span>
          {`${formatCurrency(detail?.transAmount)} ${detail?.transCurrency}`}
        </div>
        <div className={`flex-between ${styles.statusItem}`}>
          <span className={styles.label}>{t('状态')}</span>
          {upperCaseFristLetter(detail?.status === 'succeeded' ? 'Completed' : detail?.status)}
        </div>
      </div>
    </div>

  const rColor = (finish: any) => {
    let c = finish ? '#333333' : '#777777'
    return c;
  }

  const ProgressCard = () => {
    return <div className={styles.progress}>
      <div className={styles.timeLine}>
        <Timeline mode='left'>
          {
            (Array.isArray(progress) ? progress : []).map((v: IProgress, i: number) =>
              <Timeline.Item
                key={i}
                className={v.finish ? '' : styles.newBor}
                dot={<img src={v.icon} alt="" style={{ width: 26, height: 26 }} />
                }>
                <span className={styles.timeLabel}>
                  <span className={styles.date}>{v.createTime}</span>
                  <>
                    <span style={{ color: rColor(v.finish) }}>
                      {t(v.label ?? '')}
                    </span>
                  </>
                </span>
              </Timeline.Item>)
          }
        </Timeline>
      </div>
      <div className={styles.btnContent}>
        <div className={styles.handleBtn}>
          <Button type="primary" className={`${styles.b} ${styles.b3}`} ghost onClick={() => {
            setReceiptVisible(true)
          }}>
            {t("收据回执")}
          </Button>
        </div>
        <div className={styles.phoneBtn}>
          <img src={Phone} alt="" />
          {t("客服热线：")}+254 768-985-998
        </div>
      </div>
    </div >
  }

  return (
    <div className={styles.detailRoot}>
      {receiptVisible && <Receipt setVisible={setReceiptVisible} data={detail} />}
      <BackButton />
      <div className={styles.bg} />
      <div className={`${styles.row}`} style={detail?.isRefund ? { justifyContent: 'center' } : {}}>
        <div className={styles.c1} >
          <div className={cls(styles.container)}>
            <div className={styles.itemBox}>
              <div className={cls(styles.itemLabel)}>
                {detail?.isRefund ? t('退款信息') : (isPayout ? t('支付信息') : t('收款信息'))}
              </div>
              {ReceiptCard()}
            </div>
          </div>
        </div>
        {!detail?.isRefund && <div className={styles.c2} >
          <div className={cls(styles.container)}>
            <div className={styles.itemBox}>
              <div className={cls(styles.itemLabel, styles.proLable)}>
                {t('进度')}
              </div>
              {ProgressCard()}
            </div>
          </div>
        </div>}
      </div>
    </div>)
}

export default PcDetail;