import React, { FC, useState, useMemo, useEffect } from 'react'
import { Button, Select, Form, message, Spin } from 'antd';
import styles from './index.module.scss'
import {
  quickPayDeliveryOptions,
  quickPayCountryOptions,
  quickPayRecipientNation,
  QuickPayDelivery,
} from '@/utils/defaultData';
import { DefaultInput, DefaultSelect, SelectPicture } from '@/components';
import { checkDisabled, regularData } from '@/utils/mainUtils';
import { useTranslation } from "react-i18next";
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import _ from 'lodash';
import { recipientApi } from '@/api';
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import Card2 from '@/assets/image/recipient/card2.png';
import { getParameters } from '@/utils/mainUtils';
import { useHistory } from 'react-router-dom';
import { Nationality } from '@/api/auth/auth'

const { Option } = Select;

interface IInvitationPageProps {

}

const InvitationPage: FC<IInvitationPageProps> = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { fullName, slug } = getParameters();
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bankData, setBankData] = useState<any[]>([]);
  const locale = localStorage.getItem('wapipay-website-locale');
  const selectData = [
    { name: t("上传身份证/护照"), icon: Card2, key: 'idPhoto', src: '', val: '' },
  ]
  const [fileData, setFileData] = useState<any>(selectData)
  const [users, setUsers] = useState<any[]>([]);
  const [isCny, setIsCny] = useState(false);
  const [currentReceiveType, setCurrentReceiveType] = useState<QuickPayDelivery>();
  const isLocalBank = useMemo(() => currentReceiveType === QuickPayDelivery.LocalBank, [currentReceiveType])
  const isWapiAccount = useMemo(() => currentReceiveType === QuickPayDelivery.WapipayAccount, [currentReceiveType])

  const [searchKeyword, setSearchKeyword] = useState('');
  const [fetching, setFetching] = useState<boolean>(false);
  const [idType, setIdType] = useState(1);

  const onFinish = async (values: any) => {
    const { receiveType, bankCode, prefix, countryId, recipientUserId, phone } = values || {};

    if (receiveType === QuickPayDelivery.MPesa && countryId !== Nationality.Kenya) {
      message.warning(t("抱歉，M-PESA不适用于此收款人的国家/地区。"))
      return;
    }
    try {
      setIsLoading(true);
      const { label } = quickPayCountryOptions.find((item: any) => item.codeVal === countryId) || {};
      const { bankName } = bankData.find((item: any) => item.bankCode === bankCode) || {};
      const params: any = {
        receiveType: receiveType + '',
        firstName: values.fullName,
        // lastName: values.lastName,
        // fullName: values.fullName,
        countryCallingCode: prefix + '',
        country: label,
        countryId,
        idType: values.idType,
        idNumber: values.idNumber,
        email: values.email,
        address: values.address,
        token: slug,
      }
      if (isWapiAccount) {
        const userItem = users.find(i => i.id === recipientUserId)
        if (!userItem || !userItem?.id) {
          message.warning(t('该用户未注册Wapi账号'))
          setIsLoading(false);
          return;
        }
        const handPhone = userItem.handPhone.replace(new RegExp('^' + prefix), '')
        params.recipientUserId = userItem.id
        params.phoneNumber = userItem.handPhone
        params.mobile = handPhone
      } else {
        params.phoneNumber = `${prefix}${phone}`
        params.mobile = `${phone}`
      }
      if (receiveType === QuickPayDelivery.LocalBank) {
        params.bankAccountNumber = values.accountNo
        params.bankName = bankName
        params.bankCode = values.bankCode
        params.bankAccountMobile = values.bankAccountMobile
        if (isCny) {
          params.province = values.province
          params.city = values.city
          params.postalCode = values.postalCode
          params.bankBranch = values.bankBranch
          if (fileData[0]?.val) {
            params.idPhoto = fileData[0].val
          }
        }
      }
      await recipientApi.invitationAddRecipient(params, slug)
      setIsLoading(false);
      message.success(t('添加成功'));
      history.push(`/invitationSuccess?fullName=${fullName}`)
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
  }, [])

  const onFileChange = async (e: any, record: any) => {
    const { response, status, originFileObj } = e.file;
    let url = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(originFileObj);
      reader.onload = () => resolve(reader.result);
    });
    if (status === 'done') {
      const { data = [] } = response || {}
      const newArr = [...fileData]
      const fileSrc = data && data.length ? data[0]?.filePath : '';
      if (!fileSrc) return;
      newArr.forEach((item: any) => {
        if (item.key === record.key) {
          item.val = fileSrc;
          item.src = url;
          form.setFieldsValue({ [item.key]: url });
        }
      })
      setFileData(newArr);
    }
  }

  const onLocaleChange = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem("wapipay-website-locale", value);
  };

  const getBanksList = async (countryCode?: string) => {
    const data = await recipientApi.queryBankList({ countryCode })
    setBankData(data)
  }

  const getUsersList = async (keyword: string) => {
    setSearchKeyword(keyword)
    if (!keyword) {
      setUsers([])
      return
    }
    try {
      setFetching(true)
      const prefix = form.getFieldValue('prefix')
      const searchRes = await recipientApi.invitationPageSearchUser({ keyword: `${prefix}${keyword}` }, slug)
      setUsers(searchRes || [])
      setFetching(false)
    } catch (error) {
      setFetching(false)
    }
  };

  const fetchUsers = _.debounce(getUsersList, 800);

  const disabledSubmit = () => {
    if (!regularData.email.test(form.getFieldValue('email'))) {
      return true
    }
    if (!isWapiAccount && !regularData.phone.test(form.getFieldValue('phone'))) {
      return true
    }
    switch (currentReceiveType) {
      case QuickPayDelivery.LocalBank:
        if (!checkDisabled(form, isCny ? 16 : 12)) {
          return true
        }
        break;
      default:
        if (!checkDisabled(form, 9)) {
          return true
        }
    }
    return false
  }


  return (
    <div className={`${styles.root}`}>
      <HeadComponent isHideHome />
      <div className='login-form-container'>
        <Form
          form={form}
          onFinish={onFinish}
          className="login-form"
          onValuesChange={() => setIsUpdate(!isUpdate)}
          initialValues={{ prefix: 254, country: 2 }}
        >
          <div className={styles.title}>{fullName ? `${fullName.replace("-", " ")}` : ''}{t("想转帐给你，请在下面提供你的信息。")}</div>
          <div className={styles.formBox}>
            <div className={styles.formTitle}>{t("个人信息")}</div>
            <DefaultSelect
              dropdownMatchSelectWidth={false}
              formItemLabel={t("收款方式")}
              formItemName="receiveType"
              onChange={(val: any) => {
                setCurrentReceiveType(val)
                const countryId = form.getFieldValue('countryId')
                if (countryId && val === QuickPayDelivery.LocalBank) {
                  getBanksList(countryId)
                }
              }}
            >
              {
                quickPayDeliveryOptions.map(v =>
                  <Option
                    value={v.value}
                    key={v.value}>
                    {v.label}
                  </Option>)
              }
            </DefaultSelect>
            <SelectPicture
              formItemName="countryId"
              placeholder={t("收款方国家")}
              formItemLabel={t("收款方国家")}
              optionLabelProp="label"
              optionData={quickPayCountryOptions}
              valueKey="codeVal"
              onChange={(val: any) => {
                const { value } = quickPayCountryOptions.find((item: any) => item.codeVal === val) || {};
                form.setFieldsValue({ prefix: value });
                form.resetFields(['bankCode', 'recipientUserId'])
                setUsers([])
                const receiveType = form.getFieldValue('receiveType')
                if (receiveType === QuickPayDelivery.LocalBank) {
                  getBanksList(val)
                }
                setIsCny(val === Number(quickPayRecipientNation.CNY))
              }}
            />
            <DefaultInput
              formItemName="fullName"
              placeholder={t("收款人姓名")}
              tipsStyle={{ bottom: '-15px' }}
            />

            <div className={`form-item-warp ${styles.phoneWrap}`}>
              <Form.Item name="prefix" noStyle>
                <Select
                  style={{ minWidth: 80 }}
                  optionLabelProp="label"
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 250 }}
                >
                  {
                    quickPayCountryOptions.map((item: any) => (
                      <Option
                        value={item.value}
                        key={`${item.value}`}
                        label={
                          <div className='flex-center'>
                            <img
                              src={item.icon}
                              className="country-logo"
                              style={{ width: 21, marginRight: 5 }}
                              alt=''
                            /> <span>{item.code}</span>
                          </div>
                        }
                      >
                        <div className='flex-center'>
                          <img
                            src={item.icon}
                            className="country-logo"
                            style={{ width: 24, marginRight: 8 }}
                            alt=''
                          /> {item.label} {item.code}
                        </div>
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
              {isWapiAccount ? <DefaultSelect
                formItemName="recipientUserId"
                placeholder={t('收款人手机号码')}
                otherSelectProps={{
                  onSearch: fetchUsers,
                  filterOption: false,
                  notFoundContent: fetching ? <Spin size="small" /> : <div>{searchKeyword ? t('该用户未注册Wapi账号') : t('没有找到')}</div>,
                  showSearch: true,
                  style: {
                    width: '100%'
                  }
                }}
                otherFormItemProps={{
                  noStyle: true
                }}
                containerClassName={styles.phoneItem}
              >
                {
                  users.map((item: any) => (
                    <Option
                      value={item.id}
                      key={item.id}
                    >
                      {item.firstName} {item.lastName} ({item.handPhone})
                    </Option>
                  ))
                }
              </DefaultSelect> : <DefaultInput
                warpStyle={{ width: '100%' }}
                formItemProps={{
                  noStyle: true
                }}
                formItemName="phone"
                placeholder={t("收款人手机号码")}
                regular={regularData.phone}
              />}

            </div>
            <DefaultSelect
              dropdownMatchSelectWidth={false}
              placeholder={t("身份证类型")}
              formItemName="idType"
              onChange={(v) => {
                setIdType(v)
              }}
            >
              <Option value={1}>
                {t("身份证")}
              </Option>
              <Option value={2}>
                {t("护照")}
              </Option>
            </DefaultSelect>
            <DefaultInput
              formItemName="idNumber"
              placeholder={isLocalBank ? (idType === 2 ? t("开户时使用的护照") : t("开户时使用的身份证号")) : (idType === 2 ? t("护照") : t("身份证号"))}
              formItemTips={`${t("请输入")}${idType === 2 ? t("护照") : t("身份证号")}`}
            />
            <DefaultInput
              formItemName="email"
              placeholder={t("电子邮箱")}
              regular={regularData.email}
              inputBlurFn={() => {
                const email = form.getFieldValue('email')
                if (email) {
                  form.setFieldsValue({ email: email.toLowerCase() })
                }
              }}
            />
            {isLocalBank && isCny && <>
              <DefaultInput
                formItemName="province"
                placeholder={t("省")}
              />
              <DefaultInput
                formItemName="city"
                placeholder={t("城市")}
              />
              <DefaultInput
                formItemName="postalCode"
                placeholder={t("邮政编码")}
              // option={true}
              />
            </>}
            <DefaultInput
              formItemName="address"
              placeholder={t("地址")}
            />
            {
              isLocalBank && (
                <>
                  <div className={styles.formTitle}>{t("银行信息")}</div>
                  <DefaultSelect
                    formItemName="bankCode"
                    placeholder={t("银行名称")}
                  >
                    {
                      bankData.map((item: any) => (
                        <Option
                          value={item.bankCode}
                          key={item.id}
                        >
                          {item.bankName}
                        </Option>
                      ))
                    }
                  </DefaultSelect>
                  <DefaultInput
                    formItemName="bankBranch"
                    placeholder={t("银行支行")}
                  />
                  <DefaultInput
                    formItemName="accountNo"
                    placeholder={t("收款人银行账号")}
                    inputType="number"
                  // regular={/^\d+$/}
                  />
                  <DefaultInput
                    formItemName="bankAccountMobile"
                    placeholder={t("开户时使用的电话号码")}
                    inputType="number"
                  // regular={/^\d+$/}
                  />
                </>
              )
            }
            {isLocalBank && isCny && <>
              <div className={styles.formTitle}>{t("文件")}({t("可选")})</div>
              <div className={styles.selectList}>
                {
                  fileData.map((item: any) => (
                    <div
                      className={styles.selectLi}
                      key={item.name}
                    >
                      <UploadComponent
                        onChange={(e) => onFileChange(e, item)}
                        maxCount={1}
                        showUploadList={false}
                      >
                        {
                          item.src ? (
                            <div className={styles.picWarp}>
                              <img src={item.src} className={styles.pic} alt='' />
                              <div className={styles.text}>{t("重新上传")}</div>
                            </div>
                          ) : (
                            <img src={item.icon} className={styles.icon} alt='' />
                          )
                        }

                      </UploadComponent>
                      {/* <div className={styles.name}>{item.name}</div> */}
                    </div>
                  ))
                }
              </div>
            </>}
          </div>
          <div className={styles.btnWarp}>
            <Button
              disabled={disabledSubmit()}
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className='submit-button'
            >
              {t("提交")}
            </Button>
          </div>
        </Form>
      </div>
      <div className={`${styles['language-box']}`}>
        <div className={`${styles['language-li']}`} onClick={() => { onLocaleChange('zh') }}>中文</div>
        <div className={`${styles['language-li']}`} onClick={() => { onLocaleChange('en') }}>English</div>
      </div>
      <FooterComponent />
    </div>
  )
}

export default InvitationPage