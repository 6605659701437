import React, { FC, useEffect, useState, useMemo } from 'react'
import { Button, message, Spin, Form, Select, DatePicker, Pagination } from 'antd';
import { useTranslation } from "react-i18next";
import { history } from "@/route";
import { useMappedState, useDispatch } from "redux-react-hook";
import type { IState } from "@/redux/interface";
import styles from './AccountDetail.module.scss'
import topUpIcon from '@/assets/image/accounts/top-up.svg';
import withdrawIcon from '@/assets/image/accounts/withdraw.svg';
import quickPayIcon from '@/assets/image/accounts/quick-pay.svg';
import rightIcon from '@/assets/image/accounts/right.svg';
import { accountApi } from '@/api'
import { formatCurrency, getTransactionMethodName } from "@/utils/utils";
import { countryOptions, deliveryOptions, exchangeRateOptions } from "@/utils/defaultData";
import _ from 'lodash';
import { getToken } from '@/request';
import { status, color } from '@/pages/Order/Order'
import { useParams } from "react-router";
import { localFormat } from '@/utils/moment';
import { exportPdf } from "@/utils/mainUtils";
import AddIcon from '@/assets/image/accounts/add.svg';
import EditIcon from '@/assets/image/accounts/edit.svg';
import { Modal, DefaultInput, BackButton, NoDataCom } from '@/components';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface IAccountDetailProps {
  isSubAccount?: boolean;
}

const AccountDetail: FC<IAccountDetailProps> = (props) => {
  const { isSubAccount } = props
  const { t } = useTranslation();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [detail, setDetail] = useState<accountApi.IAccountItem>()
  const isCommission = useMemo(() => {
    return detail?.type === 'commission'
  }, [detail?.type])
  const isPayout = useMemo(() => detail?.type === 'payout', [detail])
  const [list, setList] = useState<accountApi.ITransactionItem[]>([])
  const [editSubAccountVisible, setSubEditAccountVisible] = useState<boolean>(false);
  const [addSubAccountVisible, setSubAddAccountVisible] = useState<boolean>(false);
  const [activeSubAccount, setSubActiveAccount] = useState<accountApi.IAccountItem>();
  const [isPass, setIsPass] = useState<boolean>(false);
  const [isEditPass, setIsEditPass] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [rangeDate, setRangeDate] = useState<moment.Moment | undefined | null>()
  const params: { id: string } = useParams() || {}
  const accountId = params.id || ''
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 81,
  });
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    const token = getToken()
    if (token) {
      initData()
    }
  }, [])

  const updateTransactions = async () => {
    try {
      setLoading(true)
      const data = await queryAccountTransactions()
      setList(data.records)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const initData = () => {
    try {
      setLoading(true)
      Promise.all([getAccountDetail(), queryAccountTransactions()])
        .then((res) => {
          setList(res[1].records)
          pagination.total = res[1].total
          setPagination({ ...pagination })
          setDetail(res[0])
        })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getAccountDetail = async () => {
    const data = await accountApi.getAccountDetail(accountId)
    return data
  }

  const updateAccountDetail = async () => {
    const data = await getAccountDetail()
    setDetail(data)
  }

  const queryAccountTransactions = async () => {
    const params: accountApi.ITransactionsParams = {
      current: pagination.current,
      size: pagination.pageSize,
      accountId: Number(accountId)
    }
    const data = await accountApi.queryAccountTransactions(params)
    return data
  }

  const onShowSizeChange = (current?: number) => {
    if (current) {
      pagination.current = current
      setPagination({ ...pagination })
      updateTransactions()
    }
  };

  if (!userInfoState.id) {
    return <div></div>
  }

  const exportStatement = async () => {
    if (!rangeDate) {
      message.warning(t("请选择月份！"))
      return
    }
    try {
      setExportLoading(true);
      const params: accountApi.IExportStatementReq = {
        year: +rangeDate.format('YYYY'),
        month: +rangeDate.format('MM'),
        accountId
      }
      const res = await accountApi.exportStatement(params)
      exportPdf(res, `${detail?.name}-${params.year}-${params.month}`)
      // message.success(t("添加成功！"))
      setExportLoading(false);
    } catch (error) {
      setExportLoading(false);
    }
  }

  const onFinish = async (values: any) => {
    try {
      setModalLoading(true);
      await accountApi.addAccount({ ...values, parentId: +accountId })
      message.success(t("添加成功！"))
      form.resetFields()
      setSubAddAccountVisible(false)
      setIsPass(false)
      updateAccountDetail()
      setModalLoading(false);
    } catch (error) {
      setModalLoading(false);
    }
  }

  const onEditFinish = async (values: any) => {
    if (!activeSubAccount) {
      return
    }
    try {
      setModalLoading(true);
      await accountApi.editAccount(activeSubAccount.id, values)
      message.success(t("修改成功！"))
      form.resetFields()
      setSubEditAccountVisible(false)
      setIsEditPass(false)
      updateAccountDetail()
      setModalLoading(false);
    } catch (error) {
      setModalLoading(false);
    }
  }


  return (
    <Spin spinning={loading} tip="Loading">
      <BackButton />
      <div className={styles.root}>
        <div className={styles.topCard}>
          <div className={styles.accountInfo}>
            <div className={styles.title}>{isCommission ? t("CommissionAccount") : (isPayout ? t("支付账户") : t("收款账户"))}</div>
            <div className={styles.amount}>
              <span>{formatCurrency(detail?.balance || 0)}</span>
              <span>{detail?.currency}</span>
            </div>
            <div className={styles.amountTip}>{t("可用余额")}</div>
          </div>
          <div className={styles.btnsWrap} style={isPayout ? { justifyContent: 'space-between' } : {}}>
            {isCommission ? <div
              className={styles.topUpIcon}
              onClick={() => {
                message.info(t('请联系Wapi工作人员！'))
              }}
            >
              <img className={styles.topUpIcon} src={withdrawIcon} alt="" />
              <span>{t("提现")}</span>
            </div> : <>
              <div
                className={styles.topUpIcon}
                onClick={() => {
                  history.push(`/myAccounts/${detail?.id}/topup`);
                }}
              >
                <img className={styles.topUpIcon} src={topUpIcon} alt="" />
                <span>{t("充值")}</span>
              </div>
              {isPayout && <div
                className={styles.topUpIcon}
                onClick={() => {
                  history.push(`/myAccounts/${detail?.id}/quickpay`);
                }}
              >
                <img className={styles.topUpIcon} src={quickPayIcon} alt="" />
                <span>{t("快速转账")}</span>
              </div>}
            </>}

          </div>
        </div>

        {userInfoState.isAgent && !isSubAccount && detail?.type !== 'commission' && <>
          <div className={styles.subTitle}>{t("子账户")}</div>
          <div
            className={styles.addAccount}
            onClick={() => {
              setSubAddAccountVisible(true)
            }}
          >
            <img src={AddIcon} alt="" />
            <span>{t("新增子账户")}</span>
          </div>

          {detail?.subAccounts?.map(item => {
            const countryItem = countryOptions.find(i => i.currency === item.currency)
            return <div
              className={styles.accountItem}
              key={item.id}
              onClick={() => {
                history.push(`/myAccounts/subAccount/${item.id}`);
              }}
            >
              <div className={styles.topInfo}>
                <span>{item.name}</span>
                <img
                  src={EditIcon}
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation()
                    editForm.setFieldsValue({
                      name: item.name
                    })
                    setSubActiveAccount(item)
                    setSubEditAccountVisible(true)
                  }}
                />
                <span className={styles.accountType}>{item.type ? item.type.charAt(0).toUpperCase() + item.type.slice(1) : ''}</span>
              </div>
              <div className={styles.bottomInfo}>
                <span>{formatCurrency(item.balance)}</span>
                {countryItem && <img src={countryItem.icon} alt="" />}
                <span className={styles.accountType}>{item.currency}</span>
              </div>
            </div>
          })}
        </>}

        {Boolean(list?.length) ? <>
          <div className={styles.actions} style={userInfoState.isAgent ? { paddingTop: 25 } : {}}>
            <div>{t('交易记录')}</div>
            <DatePicker
              dropdownClassName="default-date-range-picker"
              picker="month"
              format={'MM-YYYY'}
              className={styles.rangePicker}
              size='large'
              value={rangeDate}
              onChange={(v) => {
                setRangeDate(v)
              }}
            />
            <Button
              className={styles.button}
              onClick={exportStatement}
              loading={exportLoading}
            >
              {t("导出")}
            </Button>
          </div>

          <ul className={styles.transactions}>
            {list.map(item => {
              let statusText = ''
              try {
                if (item.status === 'succeeded') {
                  statusText = 'Complete'
                } else {
                  statusText = item.status.charAt(0).toUpperCase() + item.status.slice(1)
                }
              } catch (error) { }
              return <li
                className={styles.item}
                key={item.id}
                onClick={() => {
                  if (!isCommission) {
                    history.push(`/myAccounts/${detail?.id}/record/${item.id}`);
                  }
                }}
                style={isCommission ? { cursor: 'auto' } : {}}
              >
                <div className={styles.transaction}>
                  <span>Transaction id</span>
                  <span>{item.id}</span>
                </div>
                {!isCommission && <div className={styles.name}>
                  <span>{item.transUserFullName}</span>
                  <div className={styles.transferInfo}>
                    <span>{getTransactionMethodName({
                      method: item.method,
                      type: item.type,
                      accountName: item.accountName,
                      transAccountName: item.transAccountName,
                      isPaymentMethod: true,
                      isRefund: item.isRefund,
                    })}</span>
                    <img src={rightIcon} alt="" />
                    <span>{getTransactionMethodName({
                      method: item.receiverMethod,
                      type: item.type,
                      accountName: item.accountName,
                      transAccountName: item.transAccountName,
                      isPaymentMethod: false,
                      isRefund: item?.isRefund,
                    })}</span>
                  </div>
                </div>}
                <div className={styles.date}>
                  <span>{localFormat(item.createdAt, 'DD-MM-YYYY')}</span>
                  {item.type === 'payout' ? <span style={{ color: '#FF5C00' }}>-{formatCurrency(item.amount || 0)} {item.currency}</span> : <span>
                    {formatCurrency(item.amount || 0)} {item.currency}
                  </span>}

                </div>
                <div className={styles.statusWrap}>
                  <div style={{ borderColor: (color as any)[statusText] }} className={`${styles.status} flex-all-center`}>
                    <img src={status[statusText as string]} alt="" />
                    <span style={{ color: (color as any)[statusText] }}>{statusText}</span>
                  </div>
                  <span>{t('余额')}: {formatCurrency(item.balance || 0)} {item.currency}</span>
                </div>
              </li>
            })}
          </ul>
          <div className={styles.pageSizeBox}>
            <Pagination
              onChange={onShowSizeChange}
              showSizeChanger={false}
              showTotal={(total: any) => `Total ${total} items`}
              {...pagination}
            />
          </div>
        </> : <NoDataCom
          title={t('没有交易记录')}
        />}

      </div>

      {addSubAccountVisible && <Modal
        title={t("新增子账户")}
        setIsModalVisible={setSubAddAccountVisible}
        maskClosable={true}
      >
        <div className='login-form-container'>
          <Form
            form={form}
            onFinish={onFinish}
            className="login-form"
            onValuesChange={(current, values) => {
              setIsPass(Boolean(String(values.name).trim()))
            }}
          >
            <div className={styles.formBox}>
              <DefaultInput
                formItemName="name"
                placeholder={t("输入钱包名")}
                formItemLabel={t("输入钱包名")}
                maxLength={50}
              />
            </div>
            <div className={styles.btnWarp}>
              <Button
                disabled={!isPass}
                type="primary"
                htmlType="submit"
                loading={modalLoading}
                className='submit-button'
              >
                {t("提交")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>}

      {editSubAccountVisible && <Modal
        title={t("编辑子账户")}
        setIsModalVisible={setSubEditAccountVisible}
        maskClosable={true}
      >
        <div className='login-form-container'>
          <Form
            form={editForm}
            onFinish={onEditFinish}
            className="login-form"
            onValuesChange={(current, values) => {
              setIsEditPass(Boolean(String(values.name).trim()))
            }}
          >
            <div className={styles.formBox}>
              <DefaultInput
                formItemName="name"
                placeholder={t("输入钱包名")}
                formItemLabel={t("输入钱包名")}
                maxLength={50}
              />
            </div>
            <div className={styles.btnWarp}>
              <Button
                disabled={!isEditPass}
                type="primary"
                htmlType="submit"
                loading={modalLoading}
                className='submit-button'
              >
                {t("提交")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>}
    </Spin>
  )
}

export default AccountDetail
