import $request from '@/request';


export interface IAccountItem {
  "id": number;
  "accountNo": string;
  "userId": number;
  "parentId": number;
  "name": string;
  "type": string;
  "status": number;
  "currency": string;
  "balance": number;
  "maxBalanceLimit": number;
  subAccountIdentifier?: string;
  subAccounts?: IAccountItem[];
}

export interface IAddAccountReq {
  "name": "string",
  "currency"?: "string",
  "parentId"?: "number",
}

export interface IQueryAccountsReq {
  leftJoinSubAccounts?: boolean;
  type?: string;
}

export interface ITransactionsParams {
  current?: number;
  size?: number;
  accountId?: number;
}

export interface ITransactionItem {
  "id": 310,
  "amount": 1,
  "balance": 9,
  "type": string,
  "method": string,
  "receiverMethod": "account",
  "userId": 191,
  "userFullName": "Eddie Wan",
  "custType": "3",
  "parentId": 0,
  "currency": "KES",
  "transAccountId": 839,
  "transUserId": 191,
  "transUserFullName": "Eddie Wan",
  "transCustType": "3",
  "transParentId": 0,
  "transCurrency": "KES",
  "exchangeRateForeignCurrency": string,
  "transAmount": 0,
  "exchangeRate": 0,
  "exchangeRatePercent": 0,
  "commissionPercent": 0,
  "status": string,
  "serviceFee": 0,
  "createdAt": "2024-08-09 10:14:32",
  "updatedAt": "2024-08-09 10:14:52",
  "wapiRemitMethod": {
    "code": 1,
    "name": "express_deposit"
  },
  "paymentMethodId": 17,
  "transAccountName": "Eddie KES",
  "refNo": "1723187671755869",
  progressList: any[],
  accountName: "";
  isRefund: boolean;
}

export interface ITransactionsRes {
  "records": ITransactionItem[],
  "total": number;
  "size": number;
  "current": number;
  "orders": any[],
  "pages": number;
}

export interface IPaymentMethodItem {
  "id": number,
  "userId": number,
  "type": string,
  "mobile": string,
  "createdAt": string,
  "updatedAt": string
}

export interface IExportStatementReq {
  "year": number;
  "month": number;
  accountId: string;
}

export interface IAddPaymentMethodReq {
  "mobile": string;
  "type": string;
  "countryCallingCode": string;
  "phoneNumber": string;
}

export interface ITopUpReq {
  "amount": string;
  "fromAccountId"?: number;
  paymentMethodId?: number;
}

export interface IQuickPayReq {
  amount: string;
  recipientId: number;
  reason: number;
  source: number;
  currency: string;
  transCurrency: string;
  description: string;
}

export interface IPreQuickPayReq {
  amount: string;
  currency: string;
  transCurrency: string;
}

export interface IPreQuickPayReS {
  "subtotal"?: number;
  "commission"?: number;
  "exchangeRateFee"?: number;
  "serviceFee"?: number;
  "total"?: number;
  "currency"?: string;
  "transCurrency"?: string;
  "transAmount"?: number;
  "exchangeRateId"?: number;
  "exchangeRateLocalCurrency"?: string;
  "exchangeRateForeignCurrency"?: string;
  "exchangeRate"?: number;
  "exchangeRatePercent"?: number;
  "commissionPercent"?: number;
}


export interface IGetAccountDetailParams {
  subAccountIdentifier?: string;
}

export const queryAccounts = (params?: IQueryAccountsReq): Promise<IAccountItem[]> => {
  return $request.get('/remit-service/remit/accounts', { params: { ...(params || {}), showAll: true } })
}

export const addAccount = (data: IAddAccountReq): Promise<any> => {
  return $request.post('/remit-service/remit/accounts', data)
}

export const getAccountDetail = (accountId: string | number, params?: IGetAccountDetailParams): Promise<IAccountItem> => {
  return $request.get(`/remit-service/remit/accounts/${accountId}`, { params })
}

export const queryAccountTransactions = (params: ITransactionsParams): Promise<ITransactionsRes> => {
  return $request.get('/remit-service/remit/accounts/history', {
    params
  })
}

export const getTransactionDetail = (id: string): Promise<ITransactionItem> => {
  return $request.get(`/remit-service/remit/accounts/history/${id}`)
}

export const exportStatement = (data: IExportStatementReq): Promise<any> => {
  return $request.post('/remit-service/remit/accounts/exportStatement', data, {
    responseType: 'blob'
  })
}

export const editAccount = (accountId: number, data: IAddAccountReq): Promise<any> => {
  return $request.put(`/remit-service/remit/accounts/${accountId}`, data)
}

export const queryPaymentMethods = (): Promise<IPaymentMethodItem[]> => {
  return $request.get('/remit-service/remit/payment_methods')
}

export const addPaymentMethod = (data: IAddPaymentMethodReq): Promise<any> => {
  return $request.post('/remit-service/remit/payment_methods', data)
}

export const accountTopUp = (accountId: string | number, data: ITopUpReq): Promise<any> => {
  return $request.post(`/remit-service/remit/accounts/${accountId}/top_up`, data)
}

export const accountQuickPay = (accountId: string | number, data: IQuickPayReq): Promise<any> => {
  return $request.post(`/remit-service/remit/accounts/${accountId}/quick_pay`, data)
}

export const preQuickPay = (accountId: string | number, data: IPreQuickPayReq): Promise<IPreQuickPayReS> => {
  return $request.post(`/remit-service/remit/accounts/${accountId}/payout_breakdown`, data)
}