import React, { useState, FC, useEffect } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import { SAVE_USER_INFO } from '@/redux/action'
import { useHistory } from 'react-router';
import './Login.scss'
import { authApis } from '@/api'
import { useMappedState, useDispatch } from "redux-react-hook";
import { IState } from '@/redux/interface'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import { countryOptions } from '@/utils/defaultData'
import { checkDisabled } from '@/utils/mainUtils'
import { DefaultInput } from '@/components';
import { useTranslation } from "react-i18next";
import loginIcon1 from "@/assets/image/login/login-icon1.png";
import loginIcon2 from "@/assets/image/login/login-icon2.png";
import moment from 'moment'
import SendCodeCom from '@/components/SendCodeCom';
import { RegistrationStatus } from '@/api/auth/auth';
import { PASSWORD } from '@/utils/regular';

const { Option } = Select;

interface ILoginProps {
  loginSuccess: () => void;
  showTitle?: boolean;
  buttonStyle?: React.CSSProperties;
  buttonText?: string;
  setLoginVisible?: (v: boolean) => void;
}


const Login: FC<ILoginProps> = (props) => {
  const { t, i18n } = useTranslation();
  const publicConfig = useMappedState((state: IState) => state.PublicConfigReducer.publicConfig);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [havePhone, setHavePhone] = useState<boolean>(true);
  const [havePassword, setHavePassword] = useState<boolean>(true);
  const [isPhoneFocus, setIsPhoneFocus] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCodeLogin, setIsCodeLogin] = useState(false);
  const history = useHistory();
  const [sendLoading, setSendLoading] = useState(false)
  const [showPwdError, setShowPwdError] = useState(false);

  const onLocaleChange = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem("wapipay-website-locale", value);
  };

  const onFinish = async (values: any) => {
    const { userName, password, smsCode, prefix } = values || {};
    if (!isCodeLogin && !validatePwdFn(password)) {
      return
    }
    setHavePhone(userName ? true : false);
    setHavePassword(password ? true : false);
    if (isCodeLogin) {
      if (!userName || !smsCode) return;
    } else {
      if (!userName || !password) return;
    }
    try {
      setLoading(true)
      let authRes: any;
      if (!isCodeLogin) {
        authRes = await authApis.authLoginIn({
          userName: prefix + '' + userName, password
        })
      } else {
        authRes = await authApis.loginByPhone({
          handPhone: Number(prefix + '' + userName), smsCode
        })
      }
      // @ts-ignore
      const expires = new Date().toGMTString ? `expires=${moment().add(7, 'd').toDate().toGMTString()}` : ''
      document.cookie = `token=${authRes.token};path=/;${expires}`
      window.localStorage.setItem('userInfo', JSON.stringify(authRes.user))
      dispatch({ type: SAVE_USER_INFO, userInfo: authRes.user })
      setLoading(false)
      const { registrationStatus } = authRes.user || {};
      if (registrationStatus === RegistrationStatus.Unfinished) {
        history.push('/register')
      } else {
        history.push('/home')
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const onInputChange = (e: any, fn: (flag: boolean) => void) => {
    const { value } = e.target || {};
    fn(value ? true : false)
  }

  const onInputFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ minWidth: 80 }}
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 250 }}
        onFocus={() => onInputFocus(setIsPhoneFocus, true)}
        onBlur={() => onInputFocus(setIsPhoneFocus, false)}
        onSelect={() => onInputFocus(setIsPhoneFocus, false)}
      >
        {
          countryOptions.map((item: any) => (
            <Option
              value={item.value}
              key={`${item.value}`}
              label={
                <div className='flex-center'>
                  <img
                    src={item.icon}
                    className="country-logo"
                    style={{ height: 14, marginRight: 5 }}
                    alt=''
                  /> <span>{item.code}</span>
                </div>
              }
            >
              <div className='flex-center'>
                <img
                  src={item.icon}
                  className="country-logo"
                  style={{ width: 24, marginRight: 8 }}
                  alt=''
                /> {item.label} {item.code}
              </div>
            </Option>
          ))
        }
      </Select>
    </Form.Item>
  );

  const validatePwdFn = (password: string) => {
    if (!PASSWORD.test(password)) {
      setShowPwdError(true)
      return false;
    }
    setShowPwdError(false)
    return true;
  }

  return (
    <div className={'login-container'}>
      <HeadComponent />
      <div className='login-container-warp login-form-container'>
        <img alt='' src={loginIcon1} className='icon-left login-icon-bg' />
        <img alt='' src={loginIcon2} className='icon-right login-icon-bg' />
        <div className='login-box'>
          <div className='login-box-title'>{t('欢迎使用Wapi Pay！')}</div>
          <Form form={form} onValuesChange={() => setIsUpdate(!isUpdate)} initialValues={{ prefix: 254 }} onFinish={onFinish} className="login-form">
            <div className='form-item-warp'>
              <Form.Item
                name="userName"
                label=""
                className={`${!havePhone && 'form-item-tips'} ${isPhoneFocus && 'form-item-focus'}`}
              >
                <Input
                  placeholder={t('手机号码')}
                  addonBefore={prefixSelector}
                  onChange={(e) => {
                    onInputChange(e, setHavePhone)
                    // const { value } = e.target || {};
                    // form.setFieldsValue({ userName: value.replace(/[^\d]|^[0]/g,'') })
                  }}
                  type="number"
                  className='phone-input'
                  onFocus={() => onInputFocus(setIsPhoneFocus, true)}
                  onBlur={() => {
                    onInputFocus(setIsPhoneFocus, false)
                    const phone = form.getFieldValue('userName')
                    form.setFieldsValue({ userName: (phone || '').replace(/^0+/, '') })
                  }}
                />
              </Form.Item>
              {
                !havePhone && (
                  <span className='tips'>
                    <ExclamationCircleOutlined /> {t('请输入您的手机号！')}
                  </span>
                )
              }
            </div>
            {
              isCodeLogin ? (
                <SendCodeCom form={form} sendLoading={sendLoading} setSendLoading={setSendLoading} />
              ) : (
                <DefaultInput
                  formItemName="password"
                  placeholder={t('密码')}
                  isPassword
                  tipsIsAbsolute={true}
                  formItemTips={showPwdError ? t('密码格式不正确！') : t('请输入您的密码！')}
                  showError={showPwdError}
                  notes={t('密码应至少包含8个字符，包括1个大写字母、1个小写字母和1个数字。')}
                  inputBlurFn={validatePwdFn}
                />
              )
            }
            {!isCodeLogin && (<div className='forgot' onClick={() => history.push('/forgetPassword')}>{t('忘记密码？')}</div>)}

            {/* <Form.Item shouldUpdate className='no-style'>
              {() => (
                <Button disabled={!form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length} type="primary" htmlType="submit" className='submit-button'>
                  Login
                </Button>
              )}
            </Form.Item> */}
            <Button loading={loading} disabled={!checkDisabled(form, 3)} type="primary" htmlType="submit" className='submit-button'>
              {t('登录')}
            </Button>
            <div className='switch-box'>
              {t('或使用')}
              <span
                className='switch-btn'
                onClick={() => {
                  form.setFieldsValue({ smsCode: '', password: '' })
                  setIsCodeLogin(!isCodeLogin);
                }}
              >
                {isCodeLogin ? t('密码登录') : t('验证码登录')}
              </span>
            </div>
            <div className='register-btn' onClick={() => history.push('/creatAccount')}>
              {t('注册')}
            </div>
          </Form>
          <div className='language-box'>
            <div className='language-li' onClick={() => { onLocaleChange('zh') }}>中文</div>
            <div className='language-li' onClick={() => { onLocaleChange('en') }}>English</div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  )
}

export default Login
