import React, { FC, useState, useEffect } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import { DefaultInput, DefaultSelect } from '@/components';
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import styles from './IdentityCom.module.scss'
import { checkDisabled } from '@/utils/mainUtils';
import { useTranslation } from 'react-i18next';
import Card1 from '@/assets/image/login/card1.png';
import Card2 from '@/assets/image/login/card2.png';

const { Option } = Select;

interface IIdentityComProps {
	form: any;
	setFileData: (arr: any[]) => void;
	fileData: any[];
	// selectData: any[];
	// passportData: any[];
	onFileChange: (e: any, record: any, fileDataArr: any[], setFileDataArr: (arr: any[]) => void) => void;
}

const IdentityCom: FC<IIdentityComProps> = (props) => {
	const { form, setFileData, fileData, onFileChange } = props;
	const [idVal, setIdVal] = useState<string | number>(1);
	const { t, i18n } = useTranslation();
	const selectData = [
		{ name: t("身份证正面"), icon: Card2, key: 'idFront', src: '', val: '' },
		{ name: t("身份证背面"), icon: Card1, key: 'idBack', src: '', val: '' },
	]
	const passportData = [
		{ name: t("请上传护照首页"), icon: Card2, key: 'passportImg', src: '', val: '' },
	]
	const certificatesTypes = [
		{ label: t("身份证"), value: 1 },
		{ label: t("护照"), value: 2 },
	]

	return (
		<div className={styles.root}>
			<div className={styles.registerTitle}>{t("让我们来验证您的身份")}</div>
			<div className='login-form-container'>
				<DefaultSelect
					formItemName="identificationTypes"
					placeholder="Id"
					onChange={(val) => {
						setFileData(val === 1 ? selectData : passportData)
					}}
					setSelectVal={setIdVal}
				>
					{
						certificatesTypes.map((item: any) => (
							<Option value={item.value} key={`${item.value}`}>
								{item.label}
							</Option>
						))
					}
				</DefaultSelect>
				{idVal === 1 ? <DefaultInput
					formItemName="nationalId"
					placeholder={t("身份证号")}
				/> : <DefaultInput
					formItemName="passNo"
					placeholder={t("护照")}
				/>}
				<div className={`${styles.selectList} ${idVal === 2 && styles.selectListBox}`}>
					{
						fileData.map((item: any) => (
							<div
								className={styles.selectLi}
								key={item.name}
							>
								<UploadComponent
									// accept={each.accept ?? accept}
									// action={each.action ?? action}
									onChange={(e) => onFileChange(e, item, fileData, setFileData)}
									maxCount={1}
									showUploadList={false}
								>
									{
										item.src ? (
											<div className={styles.picWarp}>
												<img src={item.src} className={styles.pic} alt='' />
												<div className={styles.text}>{t("重新上传")}</div>
											</div>
										) : (
											<img src={item.icon} className={styles.icon} alt='' />
										)
									}

								</UploadComponent>
								<div className={styles.name}>{item.name}</div>
							</div>
						))
					}
				</div>
				<Button disabled={fileData.filter((item: any) => item.src).length === (idVal === 2 ? 1 : 2) ? !checkDisabled(form, 2) : true} type="primary" htmlType="submit" className='submit-button'>
					{t("下一步")}
				</Button>
			</div>
		</div>
	)
}

export default IdentityCom
