import $request from '@/request';

export interface IPolicyMetaRes {
  expireAt: string;
}

type TMobile = string

export interface IPolicyMetaReq {
  captchaRandstr: string;
  captchaTicket: string;
  mobile: TMobile;
}

export interface IAuthLoginInReq {
  userName: TMobile;
  password: string;
}

export interface IUserInfo {
  id: number;
  mobile: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAuthLoginInRes {
  token: string;
  user: IUserInfo;
}

export interface IApplication {
  newCount: number;
  inProgressCount: number;
  completedCount: number;
  totalFundingAmount: number;
}

export interface ICompany {
  id: number;
  name: string;
  copyrightCount: number;
  patentCount: number;
  ggxSubmitted: boolean;
  cgxSubmitted: boolean;
}

export interface ISendCode {
  randstr: string,
  ticket: string,
  phone: number
}

// 发送短信
export const sendSms = (data: ISendCode): Promise<number> => {
  return $request.post('remit/sms/sendSms', data, {
    headers: { 'Content-Type': 'application/json' }
  })
}

// 发送注册短信
export const sendRegisterSms = (data: ISendCode): Promise<number> => {
  return $request.post('remit/sms/sendSms4Register', data, {
    headers: { 'Content-Type': 'application/json' }
  })
}

export const submitRegister = (data: any, params?: any): Promise<any> => {
  return $request.post('remit/api/v1/sign', data, { params })
}

export const forgetPasswordApi = (data: any): Promise<any> => {
  return $request.post('remit/api/v1/resetPassword', data)
}

export const updateUserInfo = (data: any): Promise<any> => {
  return $request.post('remit/api/v1/modifyingUser', data)
}

export const checkEmail = (data: any): Promise<any> => {
  return $request.post('remit-service/remit/user/email/check_if_used', data)
}

export const sendWelcomeText = (): Promise<any> => {
  return $request.post('remit-service/remit/user/send_welcome_text')
}